import React, { useEffect, useRef } from "react";
import _ from "lodash";
import { useAudioContext } from "./audioContextProvider";

interface VisualizerProps {
  children: React.ReactNode;
}

const Visualizer: React.FC<VisualizerProps> = ({ children }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { audioContext, analyser } = useAudioContext();

  useEffect(() => {
    if (!audioContext || !analyser || !canvasRef.current || !wrapperRef.current) {
      return;
    }

    const canvas = canvasRef.current;
    const wrapper = wrapperRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const setCanvasSize = _.debounce(() => {
      if (
        wrapper.offsetWidth !== canvas.width ||
        wrapper.offsetHeight !== canvas.height
      ) {
        canvas.width = wrapper.offsetWidth;
        canvas.height = wrapper.offsetHeight;
      }
    }, 100);


    const resizeObserver = new ResizeObserver(setCanvasSize);
    resizeObserver.observe(wrapper);

    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      requestAnimationFrame(draw);

      analyser.getByteFrequencyData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      let bass = 0;
      let mid = 0;
      let treble = 0;
      const bassLimit = bufferLength * 0.2;  // Captures first 20% of the frequencies
      const midLimit = bassLimit + bufferLength * 0.3;  // Captures next 30% of the frequencies
      // Treble will capture the remaining 50%

      for (let i = 0; i < bufferLength; i++) {
        const value = dataArray[i];

        if (i < bassLimit) {
          bass += value;
        } else if (i < midLimit) {
          mid += value;
        } else {
          treble += value;
        }
      }

      bass = bass / bassLimit;
      mid = mid / (midLimit - bassLimit);
      treble = treble / (bufferLength - midLimit);

      const lineWidth = 8;
      ctx.lineWidth = lineWidth;

      // Drawing border
      const drawBorder = (color: string, alpha: number, x: number, y: number, width: number, height: number) => {
        const drawLine = (x1: number, y1: number, x2: number, y2: number) => {
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.strokeStyle = `rgba(${color}, ${alpha})`;
          ctx.stroke();
        };


        const x30 = x + (width * 0.30);
        const x70 = x + (width * 0.70);
        const y30 = y + (height * 0.30);
        const y70 = y + (height * 0.70);

        // Top border
        drawLine(x30, y, x70, y);
        // Right border
        drawLine(x + width, y30, x + width, y70);
        // Bottom border
        drawLine(x30, y + height, x70, y + height);
        // Left border
        drawLine(x, y30, x, y70);
      };

      const drawBorderMiddleLeft = (color: string, alpha: number, x: number, y: number, width: number, height: number) => {
        const drawLine = (x1: number, y1: number, x2: number, y2: number) => {
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.strokeStyle = `rgba(${color}, ${alpha})`;
          ctx.stroke();
        };

        const x30 = x + (width * 0.10);
        const x70 = x + (width * 0.30);
        const y30 = y + (height * 0.10);
        const y70 = y + (height * 0.30);

        // Top border
        drawLine(x30, y, x70, y);
        // Right border
        drawLine(x + width, y30, x + width, y70);
        // Bottom border
        drawLine(x30, y + height, x70, y + height);
        // Left border
        drawLine(x, y30, x, y70);
      };

      const drawBorderMiddleRight = (color: string, alpha: number, x: number, y: number, width: number, height: number) => {
        const drawLine = (x1: number, y1: number, x2: number, y2: number) => {
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.strokeStyle = `rgba(${color}, ${alpha})`;
          ctx.stroke();
        };

        const x30 = x + (width * 0.70);
        const x70 = x + (width * 0.90);
        const y30 = y + (height * 0.70);
        const y70 = y + (height * 0.90);

        // Top border
        drawLine(x30, y, x70, y);
        // Right border
        drawLine(x + width, y30, x + width, y70);
        // Bottom border
        drawLine(x30, y + height, x70, y + height);
        // Left border
        drawLine(x, y30, x, y70);
      };

      const drawBorderOuterLeft = (color: string, alpha: number, x: number, y: number, width: number, height: number) => {
        const drawLine = (x1: number, y1: number, x2: number, y2: number) => {
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.strokeStyle = `rgba(${color}, ${alpha})`;
          ctx.stroke();
        };

        const x30 = x + (width * 0.0028);
        const x70 = x + (width * 0.10);
        const y30 = y + (height * 0);
        const y70 = y + (height * 0.10);

        // Top border
        drawLine(x30, y, x70, y);
        // Right border
        drawLine(x + width, y30, x + width, y70);
        // Bottom border
        drawLine(x30, y + height, x70, y + height);
        // Left border
        drawLine(x, y30, x, y70);
      };

      const drawBorderOuterRight = (color: string, alpha: number, x: number, y: number, width: number, height: number) => {
        const drawLine = (x1: number, y1: number, x2: number, y2: number) => {
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.strokeStyle = `rgba(${color}, ${alpha})`;
          ctx.stroke();
        };

        const x30 = x + (width * 0.90);
        const x70 = x + (width * .997);
        const y30 = y + (height * 0.90);
        const y70 = y + (height * .997);

        // Top border
        drawLine(x30, y, x70, y);
        // Right border
        drawLine(x + width, y30, x + width, y70);
        // Bottom border
        drawLine(x30, y + height, x70, y + height);
        // Left border
        drawLine(x, y30, x, y70);
      };

      const drawSquare = (color: string, alpha: number, x: number, y: number, sideLength: number) => {
        ctx.fillStyle = `rgba(${color}, ${alpha})`;
        ctx.fillRect(x, y, sideLength, sideLength);
      };


      drawBorder("247, 109, 255", bass / 255, 0, 0, canvas.width, canvas.height);

      drawBorderMiddleLeft("17, 209, 247", mid / 255, 0, 0, canvas.width, canvas.height);
      drawBorderMiddleRight("17, 209, 247", mid / 255, 0, 0, canvas.width, canvas.height);

      drawBorderOuterLeft("112, 157, 255", treble / 255, 0, 0, canvas.width, canvas.height);
      drawBorderOuterRight("112, 157, 255", treble / 255, 0, 0, canvas.width, canvas.height);
      // drawBorderOuterRight("247, 109, 255", treble / 255, 0, 0, canvas.width, canvas.height);

      let sideLength = 10;

      drawSquare("77, 232, 255", mid / 255, 10, 10, sideLength);
      drawSquare("247, 109, 255", treble / 255, 10, 25, sideLength);
      drawSquare("247, 109, 255", treble / 255, 25, 10, sideLength);

      drawSquare("77, 232, 255", mid / 255, canvas.width - 20, 10, sideLength);
      drawSquare("247, 109, 255", treble / 255, canvas.width - 20, 25, sideLength);
      drawSquare("247, 109, 255", treble / 255, canvas.width - 35, 10, sideLength);

      drawSquare("77, 232, 255", mid / 255, 10, canvas.height - 20, sideLength);
      drawSquare("247, 109, 255", treble / 255, 10, canvas.height - 35, sideLength);
      drawSquare("247, 109, 255", treble / 255, 25, canvas.height - 20, sideLength);

      drawSquare("77, 232, 255", mid / 255, canvas.width - 20, canvas.height - 20, sideLength);
      drawSquare("247, 109, 255", treble / 255, canvas.width - 20, canvas.height - 35, sideLength);
      drawSquare("247, 109, 255", treble / 255,  canvas.width - 35, canvas.height - 20, sideLength);

    };

    draw();

    // Cleanup observer on component unmount
    return () => {
      resizeObserver.disconnect();
      setCanvasSize.cancel();
    };
  }, [audioContext, analyser, children]);

  const wrapperStyle = {
    position: "relative" as "relative",
  };

  const canvasStyle = {
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    pointerEvents: "none" as "none",
  };

  return (
    <div ref={wrapperRef} style={wrapperStyle}>
      <canvas ref={canvasRef} style={canvasStyle}></canvas>
      <div className="relative flex justify-center z-0">
        {children}
      </div>
    </div>
  );
};

export default Visualizer;

