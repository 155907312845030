const floodFill = (
    startIdx: number,
    targetIndex: number | null,
    fillIndex: number | null,
    pixels: Array<number | null>,
    gridSize: number
  ): Array<number | null> => {
    if (targetIndex === fillIndex) return pixels;
  
    const queue = [startIdx];
    const visited = new Set();
    const newPixels: Array<number | null> = [...pixels];
  
    while (queue.length > 0) {
      const idx = queue.shift();
      if (!visited.has(idx) && idx !== undefined) {
        visited.add(idx);
  
        if (newPixels[idx] === targetIndex) {
          newPixels[idx] = fillIndex;
  
          const row = Math.floor(idx / gridSize);
          const col = idx % gridSize;
  
          // Check neighbors (up, down, left, right)
          if (row > 0) queue.push((row - 1) * gridSize + col);
          if (row < gridSize - 1) queue.push((row + 1) * gridSize + col);
          if (col > 0) queue.push(row * gridSize + col - 1);
          if (col < gridSize - 1) queue.push(row * gridSize + col + 1);
        }
      }
    }
  
    return newPixels;
  };
  
  export default floodFill;
  