import React, { useEffect, useState } from 'react';
import eraserIcon from '../images/eraser.png';
import IconImage from './iconImage';
interface ColorSelectorProps {
  selectedColor: number | null;
  setSelectedColor: React.Dispatch<React.SetStateAction<number | null>>;
  palette: string[];
}

const ColorSelector: React.FC<ColorSelectorProps> = ({ selectedColor, setSelectedColor, palette }) => {
  
  const [activeColor, setActiveColor] = useState<number | null>(selectedColor);
  
  return (
    <div className="flex flex-wrap justify-center gap-2">
      <div
        className={`h-8 w-8 rounded-md border-2 flex items-center justify-center ${activeColor === null ? 'border-gray-300' : 'border-transparent'}`}
        onClick={() => {
          setActiveColor(null);
          setSelectedColor(null);
        }}
      >
        <IconImage imageSrc={eraserIcon} size={32} />
      </div>
      {palette.map((color, index) => (
        <div
          key={index}
          className={`h-8 w-8 rounded-md border-2 ${activeColor === index ? 'border-gray-300' : 'border-transparent'} ${selectedColor === index ? 'shadow-glow' : ''}`}
          style={{ backgroundColor: color }}
          onClick={() => {
            setActiveColor(index);
            setSelectedColor(index);
          }}
        ></div>
      ))}
    </div>
  );
};

export default ColorSelector;
