const palettes = [
    {
        id: 1, name: 'Sweetie 16', colors: ['#1a1c2c', '#5d275d', '#b13e53', '#ef7d57',
            '#ffcd75', '#a7f070', '#38b764', '#257179', '#29366f', '#3b5dc9', '#41a6f6',
            '#73eff7', '#f4f4f4', '#94b0c2', '#566c86', '#333c57']
    },
    {
        id: 2, name: 'Pico-8', colors: ['#000000', '#1D2B53', '#7E2553',
            '#008751', '#AB5236', '#5F574F', '#C2C3C7', '#FFF1E8', '#FF004D',
            '#FFA300', '#FFEC27', '#00E436', '#29ADFF', '#83769C', '#FF77A8', '#FFCCAA']
    },
    {
        id: 3, name: "NES", colors: ["#000000",
            "#fcfcfc",
            "#f8f8f8",
            "#bcbcbc",
            "#7c7c7c",
            "#a4e4fc",
            "#3cbcfc",
            "#0078f8",
            "#0000fc",
            "#b8b8f8",
            "#6888fc",
            "#0058f8",
            "#0000bc",
            "#d8b8f8",
            "#9878f8",
            "#6844fc",
            "#4428bc",
            "#f8b8f8",
            "#f878f8",
            "#d800cc",
            "#940084",
            "#f8a4c0",
            "#f85898",
            "#e40058",
            "#a80020",
            "#f0d0b0",
            "#f87858",
            "#f83800",
            "#a81000",
            "#fce0a8",
            "#fca044",
            "#e45c10",
            "#881400",
            "#f8d878",
            "#f8b800",
            "#ac7c00",
            "#503000",
            "#d8f878",
            "#b8f818",
            "#00b800",
            "#007800",
            "#b8f8b8",
            "#58d854",
            "#00a800",
            "#006800",
            "#b8f8d8",
            "#58f898",
            "#00a844",
            "#005800",
            "#00fcfc",
            "#00e8d8",
            "#008888",
            "#004058",
            "#f8d8f8",
            "#787878"]
    },
    {
        id: 4, name: "Pear 36", colors: ["#5e315b",
            "#8c3f5d",
            "#ba6156",
            "#f2a65e",
            "#ffe478",
            "#cfff70",
            "#8fde5d",
            "#3ca370",
            "#3d6e70",
            "#323e4f",
            "#322947",
            "#473b78",
            "#4b5bab",
            "#4da6ff",
            "#66ffe3",
            "#ffffeb",
            "#c2c2d1",
            "#7e7e8f",
            "#606070",
            "#43434f",
            "#272736",
            "#3e2347",
            "#57294b",
            "#964253",
            "#e36956",
            "#ffb570",
            "#ff9166",
            "#eb564b",
            "#b0305c",
            "#73275c",
            "#422445",
            "#5a265e",
            "#80366b",
            "#bd4882",
            "#ff6b97",
            "#ffb5b5"]
    },
    {
        id: 5, name: 'Lospec 500', colors: [
            "#10121c",
            "#2c1e31",
            "#6b2643",
            "#ac2847",
            "#ec273f",
            "#94493a",
            "#de5d3a",
            "#e98537",
            "#f3a833",
            "#4d3533",
            "#6e4c30",
            "#a26d3f",
            "#ce9248",
            "#dab163",
            "#e8d282",
            "#f7f3b7",
            "#1e4044",
            "#006554",
            "#26854c",
            "#5ab552",
            "#9de64e",
            "#008b8b",
            "#62a477",
            "#a6cb96",
            "#d3eed3",
            "#3e3b65",
            "#3859b3",
            "#3388de",
            "#36c5f4",
            "#6dead6",
            "#5e5b8c",
            "#8c78a5",
            "#b0a7b8",
            "#deceed",
            "#9a4d76",
            "#c878af",
            "#cc99ff",
            "#fa6e79",
            "#ffa2ac",
            "#ffd1d5",
            "#f6e8e0",
            "#ffffff"]
    },
    {
        id: 6, name: "Island Joy 16", colors: [
            "#ffffff",
            "#6df7c1",
            "#11adc1",
            "#606c81",
            "#393457",
            "#1e8875",
            "#5bb361",
            "#a1e55a",
            "#f7e476",
            "#f99252",
            "#cb4d68",
            "#6a3771",
            "#c92464",
            "#f48cb6",
            "#f7b69e",
            "#9b9c82"
        ]
    },
    {
        id: 7, name: "Funky Future 8", colors: [
            "#2b0f54",
            "#ab1f65",
            "#ff4f69",
            "#fff7f8",
            "#ff8142",
            "#ffda45",
            "#3368dc",
            "#49e7ec"
        ]
    },
    {
        id: 8,
        name: "Juice 56",
        colors: [
            "#000005",
            "#ffffff",
            "#c8e1eb",
            "#a5becd",
            "#7891a5",
            "#55647d",
            "#37415a",
            "#191e3c",
            "#14465a",
            "#0f7373",
            "#0fa569",
            "#41cd73",
            "#73ff73",
            "#dc9b78",
            "#b26247",
            "#8c3c32",
            "#5a1423",
            "#370a14",
            "#ffd2a5",
            "#f5a56e",
            "#e66e46",
            "#c3412d",
            "#8c2323",
            "#410041",
            "#7d0041",
            "#aa143c",
            "#d72d2d",
            "#f06923",
            "#ffaa32",
            "#ffe65a",
            "#bed72d",
            "#64a51e",
            "#237d14",
            "#0f5519",
            "#0f3223",
            "#82ffe1",
            "#41d7d7",
            "#14a0cd",
            "#1469c3",
            "#0f379b",
            "#0f0f69",
            "#3c1e8c",
            "#642db4",
            "#a041d7",
            "#e65ae6",
            "#ff8cc8",
            "#4b143c",
            "#820a64",
            "#b4236e",
            "#e65078",
            "#ff8c8c",
            "#ffcdb4",
            "#e69b96",
            "#be6973",
            "#96465f",
            "#6e2850"
        ]
    },
    {
        id: 9,
        name: "Dysto 24",
        colors: [
            "#7DF6EA", "#0E1941", "#EDF256", "#FECBA5", "#4F3D4A", "#59608D", "#6B74AC", "#1B2B51", "#CDAA92", "#68CCD2", "#E54185", "#B22E68", "#DD3645", "#FF695A", "#A52639", "#14665A", "#8CFF9A", "#42BD7F", "#FFFFFF", "#283168", "#202857", "#FFAA6F", "#A3705B", "#B483EF"
            ]
    },
    {
        id: 10,
        name: "Punks 128",
        colors: [
            "#A66E2C", "#AE8B61", "#DBB180", "#713F1D", "#FFD926", "#86581E",
            "#D29D60", "#A77C47", "#562600", "#723709", "#FFFFFF", "#EAD9D9",
            "#DDDDDD", "#C6C6C6", "#E25B26", "#E7CBA9", "#711010", "#B69F82",
            "#80DBDA", "#555555", "#8B532C", "#C42110", "#CD00CB", "#FFF68E",
            "#E22626", "#5F1D09", "#A58D8D", "#C9B2B2", "#690C45", "#8C0D5B",
            "#AD2160", "#596570", "#4A1201", "#28B143", "#281B09", "#5C390F",
            "#C77514", "#1637A4", "#1A43C8", "#142C7C", "#2A2A2A", "#85561E",
            "#CA4E11", "#933709", "#1A6ED5", "#2858B1", "#2C5195", "#293E64",
            "#2C9541", "#296434", "#8119B7", "#B261DC", "#8D8D8D", "#B4B4B4",
            "#B9B9B9", "#855114", "#683C08", "#4C4C4C", "#636363", "#D7D7D7",
            "#F0F0F0", "#328DFD", "#FD3232", "#692F08", "#595959", "#0040FF",
            "#68461F", "#C28946", "#353535", "#515151", "#D60000", "#26314A",
            "#FFD800", "#3D2F1E", "#005580", "#229000", "#C9C9C9", "#B1B1B1",
            "#D4C8B8", "#B6A389", "#CFBDA6", "#765F43", "#52321A", "#917656",
            "#FFC926", "#4F2C14", "#710CC7", "#997C59", "#988880", "#897A70",
            "#998475", "#DFDFDF", "#A49681", "#BBB3A6", "#B7AB98", "#51360C",
            "#796144", "#794B11", "#502F05", "#DC1D1D", "#506A65", "#5D7975",
            "#584733", "#311B0D", "#3C2413", "#FF8EBE", "#463827", "#A32375",
            "#B03285", "#5E4C37", "#645849", "#7DA269", "#1C1A00", "#534C00",
            "#4B3C2A", "#4F4538", "#507C33", "#5D8B43", "#5C8539", "#6E984D",
            "#5E7253", "#3C6827", "#486F2B", "#AF2C7B", "#C13F8F", "#5C736B",
            "#6E867F", "#8F0F69"
        ]
        
    }
]

export default palettes;

