import * as React from 'react'
import { useAccount, useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi'
import appConfig from '../config'
import { paintAbi } from '../abis/nftPaint';
import { fromBytes, numberToBytes } from 'viem';

interface IMint {
    pixels: number[],
    paletteId: number,
    backgroundColor: string
}


export const Mint: React.FC<IMint> = ({ pixels, paletteId, backgroundColor }) => {

    const { address, isConnected } = useAccount();


    const numberToBytesArray = (numbers: number[]) => {
        // Convert each number to a byte array
        const byteArrays = numbers.map(num => numberToBytes(num));

        // Calculate the total length for the concatenated array
        const totalLength = byteArrays.reduce((acc, bytes) => acc + bytes.length, 0);

        // Create a new Uint8Array to hold all bytes
        const result = new Uint8Array(totalLength);

        // Concatenate all byte arrays
        let offset = 0;
        for (const byteArray of byteArrays) {
            result.set(byteArray, offset);
            offset += byteArray.length;
        }

        return result;
    }

    const { config, error: prepareError, isError: isPrepareError, } = usePrepareContractWrite({
        address: appConfig.paintAddress as `0x${string}`,
        abi: paintAbi,
        functionName: 'mintPainting',
        args: [fromBytes(numberToBytesArray(pixels), 'hex'), paletteId, backgroundColor]
    });

    const { data, error, isError, write } = useContractWrite(config);

    const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
    })


    return (
        <div className='container mx-auto p-4 md:p-8 max-h-screen overflow-y-auto'>
            <h1 className="text-2xl md:text-4xl font-extrabold mb-4 text-center">Mint Painting</h1>
            <div className="text-center mb-4">
                <div>
                    <p className="text-lg"> Mint your current painting on-chain and immortalize your masterpiece.</p>

                    {
                        !isConnected &&
                        <p className="text-md text-red-400"> Must connect your wallet to mint</p>
                    }
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                {
                    (!isPrepareError && !isError) &&
                    <button className={`px-6 py-2 rounded-md text-lg font-semibold ${(!write || isLoading) ? 'bg-gray-500' : 'bg-blue-500'} text-white`} disabled={!write || isLoading} onClick={() => write?.()}>
                        {isLoading ? 'Minting...' : 'Mint'}
                    </button>
                }
                {
                    (isPrepareError || isError) &&
                    <button className={`px-6 py-2 rounded-md text-lg font-semibold bg-gray-500 text-white`} disabled={true}>
                        {'Mint'}
                    </button>
                }
                <p className="text-sm text-gray-600 mt-5">
                    <br />
                    - Minting your painting is free, but there's a gas fee to save it on-chain.
                    <br />
                    - Bigger pixel grids might mean a heftier gas fee.
                    <br />
                    - Tip: Save your painting now and mint when gas prices are low to get the best deal.
                </p>

                {isSuccess && (
                    <div className='text-center'>
                        <span className='text-green-500 text-md'>
                            Successfully minted your painting!
                        </span>
                        <a className='ml-2 text-md text-cyan-400 underline' href={`https://etherscan.io/tx/${data?.hash}`}>Etherscan</a>
                    </div>
                )}
                {(isPrepareError || isError) && (
                    <div className='text-red-400 text-md'>Error: {(prepareError || error)?.message}</div>
                )}
            </div>
        </div>
    )
}
