import React, { createContext, useContext, useEffect, useRef, useState } from "react";

interface AudioContextValue {
  audioContext: AudioContext | null;
  analyser: AnalyserNode | null;
  currentSong: string | null;
  setCurrentSong: React.Dispatch<React.SetStateAction<string | null>>;
  playSong: (songUrl: string, songTitle: string) => void; 
  stopSong: () => void; 
}

interface AudioContextProviderProps {
    children: React.ReactNode;
  }

  const AudioCtx = createContext<AudioContextValue>({
    audioContext: null,
    analyser: null,
    currentSong: null,
    setCurrentSong: () => {}, 
    playSong: () => {}, 
    stopSong: () => {}, 
  });
  

export const useAudioContext = () => useContext(AudioCtx);

export const AudioContextProvider: React.FC<AudioContextProviderProps> = ({ children }) => {
  const audioContextRef = useRef(new AudioContext({latencyHint: "interactive"}));
  const analyserRef = useRef(audioContextRef.current.createAnalyser());
  const [currentSong, setCurrentSong] = useState<string | null>(null);
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);

  const playSong = (songUrl: string, songTitle: string) => {
    if (currentAudio) {
      currentAudio.pause();
    }
  
    const audio = new Audio(songUrl);
    audio.loop = true;
  
    const source = audioContextRef.current!.createMediaElementSource(audio);
    source.connect(analyserRef.current!);
    analyserRef.current!.connect(audioContextRef.current!.destination);
  
    setCurrentAudio(audio);
    setCurrentSong(songTitle);
    audio.play();
  };
  
  const stopSong = () => {
    if (currentAudio) {
      currentAudio.pause();
      setCurrentAudio(null);
      setCurrentSong(null);
    }
    setCurrentSong(null);
  };
  
  useEffect(() => {
    return () => {
      if (currentAudio) {
        currentAudio.pause();
      }
    };
  }, []);
  
  return (
    <AudioCtx.Provider value={{ 
      audioContext: audioContextRef.current, 
      analyser: analyserRef.current, 
      currentSong,
      setCurrentSong,
      playSong, 
      stopSong 
    }}>
      {children}
    </AudioCtx.Provider>
  );
};
