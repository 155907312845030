import { useConnect, useAccount, useDisconnect, useEnsName } from 'wagmi';

export function Profile() {
  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect();
  const { disconnect } = useDisconnect();

  return (
    <div className="container mx-auto p-4 md:p-8 max-h-screen overflow-y-auto">
      <h1 className="text-2xl md:text-4xl font-extrabold mb-4 text-center">Connect Wallet</h1>
      <div className="text-center mb-4">
        {isConnected ? (
          <div>
            <p className="text-lg font-semibold">Connected:</p>
            <p className="text-md">{ensName || address}</p>
          </div>
        ) : (
          <p className="text-md font-medium">Not Connected</p>
        )}
      </div>
      <div className="flex flex-col gap-2">
        {connectors.map((conn) => (
          <button
            className={`px-6 py-2 rounded-md text-lg font-semibold ${conn.ready ? 'bg-blue-500 text-white' : 'bg-gray-400 text-gray-800'
              }`}
            disabled={!conn.ready}
            key={conn.id}
            onClick={() => connect({ connector: conn })}
          >
            {conn.name}
            {!conn.ready && ' (unsupported)'}
            {isLoading && conn.id === pendingConnector?.id && ' (connecting)'}
          </button>
        ))}
      </div>
      {error && <div className="mt-4 text-red-600 font-medium">{error.message}</div>}
    </div>
  );
}
