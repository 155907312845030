import React, { useState, useRef, useEffect } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  content: Array<{ name: string, component: React.ReactNode | (() => React.ReactNode) }>;
}

const DynamicModal: React.FC<ModalProps> = ({ isOpen, onClose, content }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedTab(0); // Reset the selected tab to the first one
  }, [content, isOpen]);

  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const renderContent = () => {
    const currentContent = content[selectedTab];
    return typeof currentContent.component === 'function' ? currentContent.component() : currentContent.component;
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50" onClick={handleOverlayClick}>
      <div className="relative bg-white rounded-lg w-full md:w-1/2 lg:w-1/3 p-4" style={{ height: content.length > 1 || content[0].name === "Load Template" ? '500px' : 'auto', overflowY: 'auto' }}>
        <button className="absolute top-2 right-2 text-gray-700" onClick={onClose}>
          X
        </button>
        {content.length > 1 && (
          <div className="flex mb-4 border-b">
            {content.map((tab, index) => (
              <button
                key={index}
                className={`flex-1 py-2 text-center ${selectedTab === index ? 'border-b-2 border-blue-500' : 'text-gray-500'}`}
                onClick={() => setSelectedTab(index)}
              >
                {tab.name}
              </button>
            ))}
          </div>
        )}
        <div ref={modalRef}>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default DynamicModal;
