import React from 'react';

interface NFTPaintComponentProps {
  pixelmageTwitterLink: string;
  bearsDeluxeTwitterLink: string;
  openseaLink: string;
  etherscanLink: string;
}

const NFTPaintComponent: React.FC<NFTPaintComponentProps> = ({
  pixelmageTwitterLink,
  bearsDeluxeTwitterLink,
  openseaLink,
  etherscanLink,
}) => {
  return (
    <div className="bg-white p-8 rounded-xl shadow-lg space-y-6">
      
      <div className="p-4 bg-gray-100 rounded-xl">
        <p className="text-xl font-semibold mb-2">
          NFT Paint: Create and Collect Pixel Art NFTs
        </p>
        <p>
          View the full documentation at <a href={'https://docs.bearsdeluxe.io/games/nft-paint'} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline" >https://docs.bearsdeluxe.io/games/nft-paint</a>
        </p>
        <p className="text-md">
          Created by{' '}
          <a
            href={pixelmageTwitterLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            pixelmage
          </a>{' '}
          from{' '}
          <a
            href={bearsDeluxeTwitterLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            bears deluxe
          </a>
        </p>
      </div>

      <div className="p-4 bg-gray-100 rounded-xl">
        <p className="text-xl font-semibold mb-2">
          Explore the Paintings
        </p>
        <p className="text-md">
          Check them out on{' '}
          <a
            href={openseaLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            OpenSea
          </a>
        </p>
      </div>

      <div className="p-4 bg-gray-100 rounded-xl">
        <p className="text-xl font-semibold mb-2">
          Art Storage Details
        </p>
        <p className="text-md">
          All art is saved on-chain. Retrieve it directly from the contract at{' '}
          <a
            href={etherscanLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            Etherscan
          </a>
        </p>
      </div>

    </div>
  );
};

export default NFTPaintComponent;
