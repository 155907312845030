export const templates = [{
    "date": "9/1/2023, 3:59:03 PM",
    "gridSize": 24,
    "paletteId": 5,
    "pixels": [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      0,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      0,
      0,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      11,
      11,
      11,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      11,
      11,
      11,
      11,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      11,
      11,
      11,
      11,
      11,
      0,
      0,
      0,
      0,
      0,
      0,
      11,
      11,
      11,
      11,
      11,
      11,
      0,
      null,
      null,
      null,
      null,
      0,
      11,
      0,
      0,
      0,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      0,
      0,
      0,
      0,
      11,
      0,
      null,
      null,
      null,
      0,
      0,
      13,
      13,
      13,
      0,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      0,
      13,
      13,
      13,
      13,
      0,
      0,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      0,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      0,
      13,
      13,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      0,
      13,
      0,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      0,
      13,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      0,
      11,
      11,
      0,
      40,
      11,
      11,
      11,
      0,
      40,
      11,
      11,
      11,
      11,
      11,
      0,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      0,
      11,
      11,
      11,
      11,
      0,
      0,
      0,
      0,
      0,
      0,
      11,
      11,
      11,
      11,
      11,
      0,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      11,
      11,
      11,
      0,
      13,
      0,
      0,
      0,
      13,
      13,
      0,
      11,
      11,
      11,
      11,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      11,
      11,
      0,
      13,
      13,
      13,
      0,
      13,
      13,
      13,
      13,
      0,
      11,
      11,
      11,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      11,
      11,
      0,
      13,
      0,
      0,
      0,
      0,
      0,
      13,
      13,
      0,
      11,
      11,
      11,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      11,
      11,
      0,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      0,
      11,
      11,
      11,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      11,
      11,
      0,
      13,
      13,
      13,
      13,
      13,
      13,
      0,
      11,
      11,
      11,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      0,
      11,
      11,
      0,
      0,
      0,
      0,
      0,
      0,
      11,
      11,
      11,
      0,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      11,
      11,
      0,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      0,
      11,
      11,
      0,
      0,
      null,
      null,
      null,
      null,
      null,
      0,
      11,
      11,
      11,
      11,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      11,
      11,
      11,
      11,
      11,
      0,
      null,
      null,
      null,
      0,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      0,
      null,
      null,
      0,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      0,
      null,
      0,
      11,
      11,
      11,
      0,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      0,
      11,
      11,
      11,
      11,
      11,
      0,
      0,
      11,
      11,
      11,
      0,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      0,
      11,
      11,
      11,
      11,
      11,
      0,
      0,
      11,
      11,
      11,
      0,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      11,
      0,
      11,
      11,
      11,
      11,
      11,
      0
    ],
    "hasBackground": true,
    "backgroundColor": "#fce4c0"
  },{
    "date": "9/1/2023, 4:15:31 PM",
    "gridSize": 24,
    "paletteId": 5,
    "pixels": [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      0,
      0,
      0,
      0,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      40,
      40,
      40,
      40,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      40,
      40,
      40,
      40,
      40,
      40,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      40,
      40,
      40,
      40,
      40,
      40,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      40,
      40,
      40,
      40,
      40,
      40,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      22,
      22,
      40,
      40,
      40,
      22,
      22,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      0,
      23,
      40,
      40,
      40,
      0,
      23,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      40,
      40,
      40,
      40,
      40,
      40,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      40,
      40,
      40,
      40,
      40,
      40,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      40,
      40,
      0,
      40,
      40,
      40,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      40,
      40,
      40,
      40,
      40,
      40,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      40,
      3,
      3,
      3,
      40,
      40,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      40,
      40,
      40,
      40,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      0,
      40,
      40,
      40,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      0,
      0,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      40,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      40,
      40,
      40,
      0
    ],
    "hasBackground": true,
    "backgroundColor": "#648596"
  },{
    "date": "9/1/2023, 4:23:19 PM",
    "gridSize": 24,
    "paletteId": 5,
    "pixels": [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      14,
      13,
      13,
      13,
      13,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      14,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      9,
      9,
      13,
      13,
      13,
      9,
      9,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      0,
      13,
      13,
      13,
      13,
      0,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      0,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      13,
      13,
      0,
      0,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      13,
      0,
      0,
      0,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      0,
      0,
      0,
      0,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      13,
      13,
      13,
      0
    ],
    "hasBackground": true,
    "backgroundColor": "#8e6fb6"
  },{
    "date": "9/1/2023, 4:55:40 PM",
    "gridSize": 32,
    "paletteId": 1,
    "pixels": [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      13,
      null,
      null,
      null,
      null,
      14,
      null,
      null,
      14,
      null,
      null,
      null,
      null,
      null,
      null,
      14,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      14,
      null,
      null,
      null,
      null,
      14,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      14,
      null,
      null,
      null,
      14,
      null,
      null,
      14,
      null,
      null,
      null,
      null,
      null,
      null,
      14,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      14,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      13,
      null,
      null,
      13,
      null,
      null,
      null,
      null,
      14,
      null,
      null,
      null,
      null,
      null,
      null,
      14,
      null,
      null,
      null,
      14,
      null,
      null,
      null,
      null,
      14,
      null,
      null,
      13,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      13,
      null,
      null,
      null,
      null,
      null,
      13,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      13,
      null,
      null,
      null,
      null,
      null,
      null,
      14,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      13,
      null,
      null,
      null,
      13,
      13,
      13,
      13,
      null,
      null,
      null,
      null,
      null,
      null,
      14,
      null,
      null,
      13,
      null,
      null,
      13,
      null,
      null,
      14,
      14,
      null,
      13,
      null,
      null,
      null,
      null,
      null,
      13,
      null,
      null,
      13,
      13,
      13,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      13,
      13,
      13,
      null,
      null,
      null,
      null,
      null,
      null,
      13,
      13,
      13,
      null,
      13,
      13,
      13,
      null,
      null,
      13,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      13,
      null,
      null,
      13,
      13,
      13,
      14,
      null,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      14,
      13,
      13,
      13,
      14,
      null,
      null,
      14,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      null,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      null,
      null,
      null,
      14,
      null,
      null,
      null,
      13,
      null,
      null,
      14,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      null,
      13,
      13,
      13,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      13,
      null,
      null,
      13,
      13,
      13,
      13,
      13,
      13,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      13,
      13,
      13,
      14,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      13,
      13,
      null,
      13,
      13,
      0,
      5,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      5,
      6,
      0,
      13,
      13,
      13,
      null,
      null,
      null,
      null,
      null,
      null,
      14,
      null,
      null,
      null,
      13,
      13,
      13,
      13,
      0,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      6,
      5,
      6,
      0,
      13,
      13,
      14,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      13,
      13,
      13,
      13,
      13,
      0,
      7,
      7,
      7,
      7,
      7,
      5,
      7,
      7,
      7,
      7,
      7,
      5,
      6,
      5,
      0,
      13,
      13,
      13,
      null,
      13,
      13,
      null,
      null,
      13,
      null,
      13,
      13,
      13,
      13,
      13,
      0,
      5,
      4,
      4,
      0,
      0,
      0,
      5,
      4,
      4,
      0,
      0,
      0,
      5,
      5,
      5,
      5,
      0,
      13,
      13,
      13,
      13,
      null,
      null,
      null,
      13,
      null,
      13,
      13,
      13,
      13,
      0,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      6,
      6,
      5,
      0,
      0,
      null,
      null,
      13,
      14,
      null,
      null,
      13,
      null,
      13,
      13,
      13,
      0,
      0,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      6,
      5,
      0,
      5,
      0,
      13,
      13,
      null,
      null,
      null,
      null,
      null,
      13,
      13,
      0,
      6,
      0,
      7,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      7,
      5,
      5,
      0,
      5,
      6,
      0,
      null,
      13,
      null,
      13,
      null,
      13,
      13,
      0,
      5,
      5,
      0,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      7,
      5,
      5,
      0,
      6,
      5,
      5,
      0,
      13,
      13,
      13,
      null,
      null,
      13,
      0,
      5,
      6,
      5,
      0,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      6,
      5,
      0,
      5,
      6,
      5,
      0,
      13,
      13,
      null,
      14,
      null,
      null,
      0,
      5,
      5,
      6,
      5,
      0,
      5,
      5,
      5,
      0,
      5,
      5,
      0,
      5,
      5,
      0,
      5,
      5,
      5,
      5,
      0,
      5,
      5,
      5,
      5,
      0,
      13,
      null,
      null,
      null,
      null,
      13,
      13,
      0,
      5,
      5,
      5,
      5,
      0,
      5,
      5,
      5,
      0,
      5,
      0,
      5,
      0,
      5,
      5,
      5,
      5,
      0,
      5,
      6,
      5,
      5,
      6,
      0,
      13,
      13,
      14,
      null,
      13,
      13,
      13,
      13,
      0,
      0,
      0,
      5,
      5,
      0,
      0,
      0,
      0,
      5,
      0,
      5,
      0,
      0,
      0,
      0,
      0,
      5,
      0,
      0,
      0,
      5,
      0,
      13,
      13,
      13,
      null,
      null,
      null,
      null,
      13,
      0,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      0,
      5,
      5,
      0,
      5,
      5,
      0,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      0,
      13,
      13,
      null,
      null,
      null,
      null,
      null,
      14,
      13,
      13,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      13,
      13,
      14,
      13,
      null,
      null,
      null,
      null,
      null,
      13,
      14,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      null,
      13,
      null,
      null,
      null,
      null,
      13,
      null,
      null,
      null,
      14,
      13,
      13,
      13,
      null,
      13,
      13,
      13,
      13,
      null,
      13,
      13,
      13,
      13,
      13,
      13,
      13,
      null,
      13,
      null,
      null,
      13,
      null,
      null,
      null,
      13,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      13,
      null,
      null,
      null,
      14,
      null,
      13,
      null,
      null,
      14,
      null,
      13,
      13,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      14,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
    "hasBackground": true,
    "backgroundColor": "#5e8586"
  },{
    "date": "9/1/2023, 5:08:27 PM",
    "gridSize": 16,
    "paletteId": 3,
    "pixels": [
      3,
      3,
      3,
      3,
      3,
      3,
      30,
      30,
      30,
      30,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      30,
      30,
      30,
      30,
      27,
      27,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      30,
      30,
      30,
      30,
      27,
      27,
      27,
      27,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      30,
      30,
      30,
      30,
      30,
      27,
      27,
      27,
      27,
      27,
      3,
      3,
      3,
      3,
      3,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      27,
      27,
      27,
      30,
      30,
      3,
      3,
      3,
      30,
      30,
      27,
      27,
      27,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      3,
      3,
      30,
      27,
      27,
      27,
      27,
      27,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      3,
      30,
      30,
      27,
      27,
      27,
      27,
      27,
      30,
      30,
      30,
      30,
      30,
      27,
      27,
      30,
      30,
      30,
      30,
      27,
      27,
      27,
      27,
      27,
      30,
      30,
      30,
      30,
      30,
      27,
      27,
      27,
      30,
      30,
      30,
      30,
      27,
      27,
      27,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      27,
      27,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      3,
      30,
      27,
      27,
      27,
      2,
      2,
      2,
      2,
      2,
      2,
      27,
      27,
      27,
      30,
      3,
      3,
      3,
      3,
      3,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      2,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      2,
      2,
      2,
      2,
      2,
      2,
      30,
      2,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      2,
      2,
      2,
      2,
      2,
      2,
      30,
      2,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      3,
      2,
      2,
      2,
      2,
      30,
      2,
      3,
      3,
      3,
      3,
      3
    ],
    "hasBackground": false,
    "backgroundColor": "#648596"
  },{
    "date": "9/1/2023, 5:17:35 PM",
    "gridSize": 16,
    "paletteId": 3,
    "pixels": [
      5,
      5,
      5,
      5,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      5,
      5,
      5,
      5,
      5,
      5,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      5,
      5,
      5,
      1,
      1,
      1,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      1,
      1,
      1,
      5,
      1,
      1,
      30,
      30,
      30,
      27,
      27,
      27,
      27,
      27,
      27,
      30,
      30,
      30,
      1,
      1,
      1,
      1,
      30,
      30,
      30,
      27,
      27,
      27,
      27,
      27,
      27,
      30,
      30,
      30,
      1,
      1,
      5,
      1,
      1,
      1,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      30,
      1,
      1,
      1,
      5,
      5,
      5,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      5,
      5,
      5,
      5,
      5,
      5,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      47,
      47,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      47,
      47,
      47,
      5,
      5,
      5,
      5,
      47,
      47,
      5,
      5,
      5,
      5,
      47,
      47,
      47,
      5,
      47,
      47,
      47,
      5,
      5,
      5,
      47,
      47,
      5,
      5,
      5,
      47,
      47,
      47,
      5,
      5,
      47,
      47,
      47,
      47,
      5,
      5,
      47,
      47,
      5,
      5,
      47,
      47,
      47,
      47,
      5,
      5,
      5,
      47,
      47,
      47,
      47,
      5,
      47,
      47,
      5,
      47,
      47,
      47,
      47,
      5,
      5,
      5,
      5,
      47,
      47,
      47,
      47,
      5,
      47,
      47,
      5,
      47,
      47,
      47,
      47,
      5,
      5,
      5,
      5,
      5,
      47,
      47,
      47,
      47,
      47,
      47,
      47,
      47,
      47,
      47,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      5,
      47,
      47,
      47,
      47,
      5,
      5,
      5,
      5,
      5,
      5
    ],
    "hasBackground": false,
    "backgroundColor": "#648596"
  },{
    "date": "9/1/2023, 5:23:14 PM",
    "gridSize": 16,
    "paletteId": 3,
    "pixels": [
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      0,
      0,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      0,
      29,
      29,
      0,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      0,
      29,
      29,
      0,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      0,
      29,
      29,
      29,
      29,
      0,
      53,
      53,
      53,
      53,
      53,
      0,
      0,
      0,
      0,
      0,
      0,
      29,
      29,
      29,
      29,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      29,
      29,
      29,
      29,
      29,
      29,
      29,
      29,
      29,
      29,
      29,
      29,
      29,
      29,
      0,
      53,
      0,
      34,
      33,
      29,
      29,
      0,
      29,
      29,
      0,
      29,
      29,
      33,
      34,
      0,
      53,
      53,
      53,
      0,
      34,
      33,
      29,
      0,
      29,
      29,
      0,
      29,
      33,
      34,
      0,
      53,
      53,
      53,
      53,
      53,
      0,
      34,
      33,
      0,
      29,
      29,
      0,
      33,
      34,
      0,
      53,
      53,
      53,
      53,
      53,
      53,
      0,
      34,
      33,
      29,
      29,
      29,
      29,
      33,
      34,
      0,
      53,
      53,
      53,
      53,
      53,
      0,
      34,
      33,
      33,
      33,
      33,
      33,
      33,
      33,
      33,
      34,
      0,
      53,
      53,
      53,
      53,
      0,
      34,
      33,
      33,
      33,
      34,
      34,
      33,
      33,
      33,
      34,
      0,
      53,
      53,
      53,
      0,
      34,
      33,
      33,
      34,
      34,
      0,
      0,
      34,
      34,
      33,
      33,
      34,
      0,
      53,
      53,
      0,
      34,
      34,
      34,
      0,
      0,
      53,
      53,
      0,
      0,
      34,
      34,
      34,
      0,
      53,
      0,
      34,
      34,
      0,
      0,
      53,
      53,
      53,
      53,
      53,
      53,
      0,
      0,
      34,
      34,
      0,
      0,
      0,
      0,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      53,
      0,
      0,
      0
    ],
    "hasBackground": false,
    "backgroundColor": "#648596"
  },
  {
    "date": "9/14/2023, 7:57:53 PM",
    "gridSize": 32,
    "paletteId": 8,
    "pixels": [
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        0,
        0,
        0,
        0,
        41,
        42,
        41,
        42,
        41,
        40,
        42,
        0,
        0,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        0,
        42,
        41,
        41,
        41,
        42,
        42,
        41,
        42,
        42,
        42,
        42,
        42,
        42,
        0,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        0,
        0,
        41,
        41,
        42,
        42,
        42,
        41,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        0,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        0,
        0,
        41,
        41,
        41,
        42,
        42,
        42,
        42,
        42,
        41,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        0,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        0,
        41,
        40,
        41,
        42,
        42,
        42,
        42,
        42,
        41,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        41,
        42,
        0,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        0,
        42,
        40,
        42,
        42,
        42,
        42,
        41,
        41,
        41,
        40,
        40,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        40,
        42,
        41,
        0,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        0,
        41,
        41,
        41,
        42,
        40,
        42,
        41,
        41,
        0,
        0,
        0,
        0,
        40,
        42,
        40,
        42,
        42,
        42,
        0,
        0,
        0,
        40,
        0,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        0,
        40,
        41,
        42,
        42,
        42,
        42,
        0,
        0,
        13,
        13,
        13,
        13,
        0,
        0,
        42,
        42,
        42,
        0,
        13,
        13,
        19,
        0,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        45,
        0,
        41,
        42,
        41,
        41,
        41,
        41,
        0,
        13,
        13,
        13,
        19,
        19,
        19,
        19,
        19,
        0,
        0,
        0,
        13,
        13,
        19,
        19,
        19,
        0,
        45,
        45,
        45,
        45,
        45,
        45,
        0,
        41,
        41,
        0,
        0,
        42,
        42,
        42,
        0,
        13,
        13,
        19,
        19,
        19,
        19,
        19,
        19,
        13,
        13,
        13,
        19,
        19,
        19,
        19,
        19,
        0,
        45,
        45,
        45,
        45,
        45,
        0,
        41,
        42,
        0,
        13,
        13,
        0,
        0,
        42,
        0,
        13,
        13,
        19,
        19,
        19,
        0,
        1,
        19,
        19,
        13,
        13,
        19,
        19,
        0,
        1,
        19,
        0,
        45,
        45,
        45,
        45,
        45,
        40,
        41,
        0,
        13,
        19,
        19,
        19,
        13,
        42,
        0,
        13,
        13,
        13,
        19,
        19,
        0,
        0,
        19,
        19,
        19,
        13,
        13,
        19,
        0,
        0,
        19,
        0,
        45,
        45,
        45,
        45,
        0,
        40,
        40,
        0,
        13,
        19,
        0,
        0,
        19,
        42,
        0,
        13,
        13,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        0,
        45,
        45,
        45,
        45,
        0,
        40,
        41,
        0,
        13,
        19,
        0,
        40,
        41,
        41,
        0,
        13,
        13,
        13,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        0,
        45,
        45,
        45,
        45,
        0,
        42,
        41,
        0,
        13,
        19,
        0,
        0,
        19,
        41,
        42,
        0,
        13,
        13,
        13,
        13,
        19,
        19,
        0,
        0,
        0,
        13,
        0,
        0,
        0,
        19,
        0,
        45,
        45,
        45,
        45,
        45,
        0,
        40,
        41,
        41,
        0,
        13,
        19,
        19,
        19,
        19,
        42,
        42,
        0,
        0,
        0,
        13,
        13,
        19,
        0,
        13,
        0,
        13,
        0,
        13,
        0,
        19,
        0,
        45,
        45,
        45,
        45,
        0,
        41,
        40,
        40,
        40,
        41,
        0,
        13,
        13,
        13,
        0,
        42,
        41,
        42,
        41,
        42,
        0,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        0,
        45,
        45,
        45,
        0,
        41,
        41,
        41,
        41,
        40,
        40,
        0,
        0,
        0,
        42,
        41,
        42,
        42,
        40,
        0,
        13,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        13,
        0,
        45,
        45,
        41,
        42,
        41,
        41,
        42,
        40,
        40,
        40,
        42,
        42,
        42,
        41,
        41,
        42,
        0,
        13,
        19,
        0,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        0,
        45,
        45,
        41,
        41,
        40,
        42,
        42,
        40,
        42,
        42,
        42,
        42,
        42,
        42,
        41,
        42,
        0,
        13,
        19,
        19,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        19,
        0,
        45,
        45,
        41,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        40,
        0,
        13,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        0,
        45,
        45,
        45,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        41,
        42,
        42,
        42,
        42,
        42,
        0,
        13,
        13,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        0,
        45,
        45,
        45,
        41,
        41,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        41,
        40,
        40,
        0,
        13,
        13,
        13,
        13,
        19,
        19,
        19,
        19,
        19,
        19,
        19,
        0,
        45,
        45,
        45,
        45,
        40,
        42,
        42,
        40,
        42,
        42,
        42,
        42,
        42,
        42,
        40,
        42,
        41,
        41,
        41,
        41,
        0,
        13,
        13,
        13,
        13,
        19,
        19,
        19,
        19,
        19,
        0,
        40,
        0,
        45,
        45,
        45,
        41,
        41,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        41,
        42,
        40,
        40,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        42,
        41,
        40,
        0,
        45,
        45,
        41,
        41,
        41,
        41,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        40,
        40,
        40,
        40,
        41,
        40,
        41,
        41,
        42,
        42,
        42,
        42,
        41,
        0,
        45,
        40,
        41,
        41,
        41,
        42,
        42,
        42,
        42,
        42,
        41,
        40,
        41,
        42,
        42,
        42,
        42,
        41,
        42,
        40,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        40,
        42,
        0,
        45,
        40,
        41,
        42,
        41,
        42,
        42,
        42,
        42,
        40,
        41,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        41,
        42,
        42,
        41,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        41,
        40,
        0,
        0,
        40,
        41,
        42,
        41,
        41,
        40,
        42,
        41,
        40,
        42,
        41,
        41,
        42,
        42,
        42,
        42,
        42,
        42,
        42,
        41,
        42,
        42,
        42,
        42,
        42,
        42,
        41,
        42,
        40,
        40,
        0
    ],
    "hasBackground": false,
    "backgroundColor": "#648596"
}
]