import * as React from 'react'
import { Mint } from '../web3/mint'

interface IMintEth {
    pixels: number[],
    paletteId: number,
    backgroundColor: string
}

export const MintPainting: React.FC<IMintEth> = ({pixels, paletteId, backgroundColor}) =>  {

    return (
        <div>
            <Mint pixels={pixels} paletteId={paletteId} backgroundColor={backgroundColor} />
        </div>
    )
}
