import React, { createContext, useState, useContext } from 'react';

interface TracingContextProps {
  imageSrc: string | null;
  setImageSrc: React.Dispatch<React.SetStateAction<string | null>>;
  isTracingEnabled: boolean;
  setIsTracingEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  position: string;
  setPosition: React.Dispatch<React.SetStateAction<string>>;
  scale: number;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  top: number;
  setTop: React.Dispatch<React.SetStateAction<number>>;
  left: number;
  setLeft: React.Dispatch<React.SetStateAction<number>>;
}

const TracingContext = createContext<TracingContextProps | undefined>(undefined);

export const useTracing = () => {
  const context = useContext(TracingContext);
  if (!context) {
    throw new Error('useTracing must be used within a TracingProvider');
  }
  return context;
};

export const TracingProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isTracingEnabled, setIsTracingEnabled] = useState(false);
  const [position, setPosition] = useState('top'); // or 'bottom'
  const [scale, setScale] = useState(1);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  return (
    <TracingContext.Provider value={{ imageSrc, setImageSrc, isTracingEnabled, setIsTracingEnabled, position, setPosition, scale, setScale, top, setTop, left, setLeft 
    }}>
      {children}
    </TracingContext.Provider>
  );
};
