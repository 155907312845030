import React from 'react';
import { useTracing } from './tracingContext';

const FileInput: React.FC<{ onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }> = ({ onChange }) => (
    <div className="mb-4">
        <label className="block text-sm font-medium text-gray-600 mb-2">Upload Image</label>
        <input type="file" accept="image/*" onChange={onChange} />
    </div>
);


const ImageUploader: React.FC = () => {
    const {
        setImageSrc, setIsTracingEnabled, isTracingEnabled,
        setScale, scale,
        setTop, top,
        setLeft, left
    } = useTracing();

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageSrc(event.target?.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-2">Image Uploader</h2>
            <p className="text-sm text-gray-600 mb-4">Upload an image to trace over the pixel grid.</p>

            <FileInput onChange={handleImageUpload} />

            <div className="mb-4">
                <span className="text-sm font-medium text-gray-600">Toggle Tracing: </span>
                <label className="inline-flex items-center ml-3">
                    <input
                        type="radio"
                        className="form-radio"
                        name="tracing"
                        checked={isTracingEnabled}
                        onChange={() => setIsTracingEnabled(true)}
                    />
                    <span className="ml-2">On</span>
                </label>
                <label className="inline-flex items-center ml-3">
                    <input
                        type="radio"
                        className="form-radio"
                        name="tracing"
                        checked={!isTracingEnabled}
                        onChange={() => setIsTracingEnabled(false)}
                    />
                    <span className="ml-2">Off</span>
                </label>
            </div>

            <div className="flex flex-col space-y-4">
                <label className="text-sm font-medium text-gray-600">Scale</label>
                <input type="range" min="0.1" max="3" step="0.01" value={scale} onChange={(e) => setScale(Number(e.target.value))} />

                <label className="text-sm font-medium text-gray-600">Top Position</label>
                <input type="range" min="-400" max="400" value={top} onChange={(e) => setTop(Number(e.target.value))} />

                <label className="text-sm font-medium text-gray-600">Left Position</label>
                <input type="range" min="-400" max="400" value={left} onChange={(e) => setLeft(Number(e.target.value))} />

            </div>
        </div>
    );
};

export default ImageUploader;
