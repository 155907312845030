// ImageOverlay.tsx
import React from 'react';
import { useTracing } from './tracingContext';

const ImageOverlay: React.FC = () => {
    const { imageSrc, isTracingEnabled, scale, top, left } = useTracing();
  
    return (
      <div className={`image-overlay ${isTracingEnabled ? 'visible' : 'hidden'}`}>
        {imageSrc && (
          <img
            src={imageSrc}
            alt="Overlay"
            style={{
              opacity: 0.5,
              position: 'absolute',
              top: `calc(50% + ${top}px)`,
              left: `calc(50% + ${left}px)`,
              transform: `translate(-50%, -50%) scale(${scale})`,
              zIndex: 1,
              pointerEvents: 'none',
              maxWidth: '100%',
              maxHeight: '100vh',
            }}
          />
        )}
      </div>
    );
  };

export default ImageOverlay;
