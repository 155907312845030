import React, { useRef } from 'react';
import Tool, { getIconByTool, specialTools, standardTools } from '../constants/toolTypes';
import IconImage from './iconImage';
import eraserIcon from '../images/eraser.png';
import clipboardIcon from '../images/clipboard.png';

interface IToolbar {
  selectedTool: Tool;
  setSelectedTool: (tool: Tool) => void;
  selectedColor: number | null;
  setSelectedColor: React.Dispatch<React.SetStateAction<number | null>>;
  palette: string[];
  undo: () => void;
  redo: () => void;
}

const Toolbar: React.FC<IToolbar> = ({ selectedTool, setSelectedTool, selectedColor, setSelectedColor, palette, undo, redo }) => {
  const textRef = useRef<HTMLSpanElement | null>(null);

  const handleCopyClick = () => {
    if (textRef.current) {
      const range = document.createRange();
      range.selectNode(textRef.current);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();

      // Alternatively, you can use Clipboard API
      // navigator.clipboard.writeText(textToCopy).then(() => {
      //   console.log('Text successfully copied');
      // });
    }
  };



  const getCurrentColorDisplay = () => {
    if (selectedColor === null) {
      return 'Erase';
    }
    return palette[selectedColor];
  };

  return (
    <div className='hidden pnt:flex'>
      <div className="absolute left-2 pnt2:left-14 top-3 bg-white  toolbar flex flex-col w-32 border border-darkgrey drop-shadow rounded p-2" >
        {/* Undo / Redo Buttons */}
        <div className="flex justify-between mb-4">
          <div></div>
          <button className='border border-1-darkgrey drop-shadow-lg pl-2 pr-2 rounded-md  hover:bg-cyan-100' onClick={undo}>Undo</button>
          <button className='border border-1-darkgrey drop-shadow-lg pl-2 pr-2 rounded-md  hover:bg-cyan-100' onClick={redo}>Redo</button>
          <div></div>
        </div>

        {/* Display Current Selected Color */}
        <div className="mb-2 flex flex-row items-center justify-center">
          <span className='mr-1'>color:</span>
          <span className='mr-1' ref={textRef}>{getCurrentColorDisplay()}</span>
          <IconImage onClick={() => { handleCopyClick() }} imageSrc={clipboardIcon} size={12} className="mt-.5 hover:cursor-pointer" />
        </div>

        {/* Color Selector */}
        <div className={`max-h-[325px] overflow-y-auto flex flex-wrap justify-center gap-2 mb-1 pb-3`}>
          <div
            className={`h-4 w-4 rounded-md border-2 flex items-center justify-center drop-shadow-lg ${selectedColor === null ? 'border-gray-300' : 'border-transparent'}`}
            onClick={() => setSelectedColor(null)}
          >
            <IconImage imageSrc={eraserIcon} size={16} />
          </div>
          {palette.map((color, index) => (
            <div
              key={index}
              className={`h-4 w-4 rounded-sm border-2 hover:cursor-pointer drop-shadow-lg ${selectedColor === index ? 'border-gray-300' : 'border-transparent'}`}
              style={{ backgroundColor: color }}
              onClick={() => setSelectedColor(index)}
            ></div>
          ))}
        </div>

        {/* Tool Selector */}
        <div className="tool-selector flex flex-col gap-0.2">
          {[...standardTools, ...specialTools].map(tool => (
            <button
              key={tool}
              className={`w-full text-left pl-1 flex flex-row whitespace-nowrap ${selectedTool === tool ? 'active text-cyan-500' : ''}`} // Added border to the left for active tool
              onClick={() => setSelectedTool(tool as Tool)}
            >
              <IconImage imageSrc={getIconByTool(tool)} size={14} className='relative top-1.5 mr-1' />
              {tool}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
