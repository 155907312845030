import { useState } from 'react';
import { useContractRead } from 'wagmi';
import { paintAbi } from '../abis/nftPaint';
import { hexToNumber } from 'viem';
import { isArray } from 'lodash';
import appConfig from '../config';

interface ILoadRemix {
  setPixels: Function;
  setBackground: Function;
  setBackgroundColor: Function;
  setGridSize: Function;
  setPalette: Function;
}

export const LoadRemix: React.FC<ILoadRemix> = ({ setPixels, setBackground, setBackgroundColor, setGridSize, setPalette }) => {
  const [paintingId, setPaintingId] = useState(0);
  const { data, isError, isLoading } = useContractRead({
    address: appConfig.paintAddress as `0x${string}`,
    abi: paintAbi,
    functionName: 'paintings',
    args: [paintingId],
  });

  const bytesToNumbersArray = (bytes: string) => {
    const cleanBytes = bytes.slice(2);
    const numbersArray = [];
    for (let i = 0; i < cleanBytes.length; i += 2) {
      const byteHex = cleanBytes.slice(i, i + 2);
      const number = hexToNumber(`0x${byteHex}`);
      numbersArray.push(number);
    }

    return numbersArray;
  };

  const loadData = () => {
    if (isArray(data) && data.length === 3) {
      let pixelsArray = bytesToNumbersArray(data[0]).map((p) => p === 129 ? null : p);
      let bgColor = data[2].slice(2).toString();
      setPixels(pixelsArray);
      setBackgroundColor('#' + bgColor);
      setGridSize(Math.sqrt(pixelsArray.length));
      setPalette(data[1]);
      setBackground(bgColor === '000000' ? false : true);
    }
  }

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-2xl font-semibold mb-4">Remix a Masterpiece</h2>
      <p className="mb-4">Dive into the world of digital artistry by remixing an existing masterpiece from the blockchain. Load a painting by its unique ID, add your creative touch, and mint it as a brand-new piece. Remember, originality thrives here; identical paintings can't be minted twice.</p>
      <input
        type="text"
        value={paintingId}
        onChange={(e) => isNaN(Number(e.target.value)) ? () => {} : setPaintingId(Number(e.target.value))}
        placeholder="Enter Painting ID"
        className="border-2 border-gray-700 rounded-md p-2 mb-4"
      />
      <button
        onClick={() => {
          if (paintingId) {
            loadData();
          }
        }}
        disabled={(isArray(data) && data[0].length >= 16*16) === false}
        className={`px-6 py-2 rounded-md text-lg font-semibold ${(isArray(data) && data[0].length >= 16*16) ? 'bg-blue-500' : 'bg-gray-500'} text-white mb-4`}
      >
        Load Painting
      </button>
      <p className={`text-sm mb-4 ${paintingId === 0 ? '' : (isArray(data) && data[0].length >= 16*16) ? 'text-green-400' : (isArray(data) && data[0] === '0x') ? 'text-red-400' : ''}`}>
        {paintingId === 0 ? '' : (isArray(data) && data[0].length >= 16*16) ? `Painting #${paintingId} is valid and can be loaded` : (isArray(data) && data[0] === '0x') ? `Painting #${paintingId} is not a valid id and cannot be loaded` : ''}
      </p>
      <p className="text-xs"><span className='text-red-400'>Caution:</span> Loading a new painting will overwrite your current canvas. Ensure you've saved any work you wish to keep before proceeding.</p>
    </div>
  );
};
