import bucketIcon from '../images/iconBucket.png';
import brushIcon from '../images/iconBrush.png';
import starIcon from '../images/star.png';
import diamondIcon from '../images/diamond.png';
import square from '../images/squareIcon.png';
import circle from '../images/circleIcon.png';
import splatter from '../images/splatter.png';

type Tool = 'Small Brush' | 'Medium Brush' | 'Large Brush' | 'Star Stamp' | 'Diamond Stamp' | 'Circle Stamp' | 'Square Stamp' | 'Splatter' | 'Bucket';

export const standardTools: Tool[] = ['Small Brush', 'Medium Brush', 'Large Brush', 'Bucket'];
export const specialTools: Tool[] = ['Splatter', 'Star Stamp', 'Diamond Stamp', 'Circle Stamp', 'Square Stamp'];

export const getIconByTool = (tool: Tool) => {
    if (tool === 'Bucket') {
        return bucketIcon;
    } else if (tool === 'Splatter') {
        return splatter;
    } else if (tool === 'Diamond Stamp') {
        return diamondIcon;
    } else if (tool === 'Star Stamp') {
        return starIcon;
    }  else if (tool === 'Circle Stamp') {
        return circle;
    }  else if (tool === 'Square Stamp') {
        return square;
    }  else {
        return brushIcon;
    }
}

export default Tool;