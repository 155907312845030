// testnet
// const appConfig = {
//     paintAddress: '0xf201fFeA8447AB3d43c98Da3349e0749813C9009'
// }

// localhost
// const appConfig = {
//     paintAddress: '0xF4151ED396b45B79Bd8ba0491f573A4c3f0Ad26E'
// }

const appConfig = {
    paintAddress: "0xa3540629c5398b88d210FAD81212Bdba11BC5Eb6"
}

export default appConfig;