import React, { useEffect, useRef, useState } from 'react';
import Tool from '../constants/toolTypes';
import floodFill from '../constants/floodFill';
import getCustomCursorStyle from '../constants/customCursor';
import throttle from 'lodash.throttle';
import ShadowCursor from './highlightMouse';
import ImageOverlay from './imageTracing/imageOverlay';

interface PixelGridProps {
  pixels: Array<number | null>;
  setPixels: (newPixels: Array<number | null>) => void;
  gridSize: number;
  showGrid: boolean;
  palette: string[];
  selectedColor: number | null;
  showBackground: boolean;
  backgroundColor: string;
  selectedTool: Tool;
}

const PixelGrid: React.FC<PixelGridProps> = ({ pixels, setPixels, gridSize, showGrid, palette, selectedColor, showBackground, backgroundColor, selectedTool }) => {
  const bgOffset = 1.15;
  const padding = 10;
  const [cellSize, setCellSize] = useState(20);
  const containerRef = useRef(null);
  const [svgSize, setSvgSize] = useState(window.innerWidth);
  const [backgroundSize, setBackgroundSize] = useState(window.innerWidth * bgOffset);
  const [isMouseDown, setIsMouseDown] = useState(false);

  useEffect(() => {
    const resizeSvg = () => {
      if (containerRef.current) {
        const { clientWidth } = containerRef.current;
        const navbar = document.getElementById('navbar');
        const navbarHeight = navbar ? navbar.offsetHeight : 0;
        const availableWidth = clientWidth;
        const availableHeight = window.innerHeight - navbarHeight;
  
        let scalingFactor = 1; // Default scaling factor
        let calculatedSvgSize = Math.min(availableWidth, availableHeight);
        let calculatedBackgroundSize = calculatedSvgSize * bgOffset;
  
        if (showBackground) {
          if (availableHeight < availableWidth) {
            calculatedBackgroundSize = availableHeight;
            calculatedSvgSize = calculatedBackgroundSize / bgOffset;
          } else {
            scalingFactor = 0.83; // Reduce size by 10%
            calculatedSvgSize = Math.min(availableWidth, availableHeight / bgOffset) * scalingFactor;
            calculatedBackgroundSize = calculatedSvgSize * bgOffset;
          }
        }
  
        setSvgSize(calculatedSvgSize);
        setBackgroundSize(calculatedBackgroundSize);
        setCellSize((calculatedSvgSize - 2 * padding) / gridSize);
      }
    };
  
    setTimeout(() => {
      resizeSvg();
    }, 0);
  
    window.addEventListener('resize', resizeSvg);
  
    return () => {
      window.removeEventListener('resize', resizeSvg);
    };
  }, [gridSize, showBackground]);
  
  
  const getBrushSize = (): number => {
    switch (selectedTool) {
      case 'Small Brush': return 1;
      case 'Medium Brush': return 3;
      case 'Large Brush': return 5;
      default: return 1;
    }
  };

  const handlePixelClick = throttle((index: number, isDrag: boolean = false) => {
    if (isDrag && !isMouseDown) return;

    const newPixels = [...pixels];
    const row = Math.floor(index / gridSize);
    const col = index % gridSize;

    const setPixel = (r: number, c: number, color: number | null) => {
      if (r >= 0 && r < gridSize && c >= 0 && c < gridSize) {
        newPixels[r * gridSize + c] = color;
      }
    };

    if (selectedTool === 'Bucket') {
      const filledPixels = floodFill(index, pixels[index], selectedColor, pixels, gridSize);
      setPixels(filledPixels);
    } else if (selectedTool === 'Diamond Stamp') {
      const starPoints = [
        [0, 0],
        [-1, -1], [1, -1], [-1, 1], [1, 1],
        [0, -2], [0, 2], [-2, 0], [2, 0]
      ];

      starPoints.forEach(([dx, dy]) => {
        setPixel(row + dx, col + dy, selectedColor);
      });

      setPixels(newPixels);
    } else if (selectedTool === 'Star Stamp') {
      // Define the points for a 5-pointed star
      const starPoints = [
        [-2, -1], [-3, 1], [-3, -1], [-1, -3], [-2, 1], [1, 3], [-1, 2], [-1, 3],
        [-1, -2], [2, 2], [3, 2], [3, -2], [2, -2], [1, -3], [3, 0],
        [0, 4], [0, -4], [-4, 0], [4, 1], [4, -1] // Tips of the star
      ];

      starPoints.forEach(([dx, dy]) => {
        setPixel(row + dx, col + dy, selectedColor);
      });

      setPixels(newPixels);
    } else if (selectedTool === 'Splatter') {
      const numberOfSplatters = Math.floor(Math.random() * 5) + 1; // Random number between 1 and 5
      for (let i = 0; i < numberOfSplatters; i++) {
        const dx = Math.floor(Math.random() * 10) - 4; // Random number between -5 and 5
        const dy = Math.floor(Math.random() * 10) - 4; // Random number between -5 and 5
        setPixel(row + dx, col + dy, selectedColor);
      }
      setPixels(newPixels);
    } else if (selectedTool === 'Square Stamp') {
      const squarePoints = [
        [-2, -1], [-2, -2], [-2, 0], [-2, 1], [-2, 2],
        [-1, -2], [-1, 2], [0, 2], [0, -2], [1, 2], [1, -2],
        [2, 1], [2, 2], [2, 0], [2, -1], [2, -2]
      ];

      squarePoints.forEach(([dx, dy]) => {
        setPixel(row + dx, col + dy, selectedColor);
      });

      setPixels(newPixels);
    } else if (selectedTool === 'Circle Stamp') {
      const circlePoints = [
        [-3, 1], [-3, 0], [-3, -1],
        [-2, 2], [-2, -2],
        [-1, 3], [-1, -3],
        [0, 3], [0, -3],
        [1, 3], [1, -3],
        [2, 2], [2, -2],
        [3, 1], [3, 0], [3, -1]
      ];

      circlePoints.forEach(([dx, dy]) => {
        setPixel(row + dx, col + dy, selectedColor);
      });

      setPixels(newPixels);
    } else {
      const brushSize = getBrushSize();

      for (let i = row - Math.floor(brushSize / 2); i <= row + Math.floor(brushSize / 2); i++) {
        for (let j = col - Math.floor(brushSize / 2); j <= col + Math.floor(brushSize / 2); j++) {
          setPixel(i, j, selectedColor);
        }
      }

      setPixels(newPixels);
    }
  }, 100);


  const handleMouseDown = (index: number) => {
    setIsMouseDown(true);
    handlePixelClick(index);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseOver = (index: number) => {
    handlePixelClick(index, true);
  };


  return (
    <div ref={containerRef} className={`flex justify-center items-end w-full h-full z-2`} style={getCustomCursorStyle(selectedTool)}>
      <ShadowCursor parentRef={containerRef}/>
      <ImageOverlay />
      <svg
        width={showBackground ? backgroundSize : svgSize}
        height={showBackground ? backgroundSize : svgSize}
        shapeRendering="crispEdges"

      >
        {showBackground && (
          <rect x={padding} y={padding} width={backgroundSize - 2 * padding} height={backgroundSize - 2 * padding} fill={backgroundColor} />
        )}
        {
          (!showBackground && !showGrid) && (
            <rect x={padding} y={padding} width={(svgSize - 2 * padding) + .5} height={(svgSize - 2 * padding) + .5} stroke='darkgrey' fill='none' />
          )
        }
        <g transform={`translate(${showBackground ? (backgroundSize - svgSize) / 2 : 0}, ${showBackground ? padding * 2 : 0})`}>
          <g transform={`translate(${padding}, ${padding + (showBackground ? (backgroundSize - svgSize - 2 * padding) : 0)})`}> 
            {Array.from({ length: gridSize * gridSize }, (_, index) => {
              const x = (index % gridSize) * cellSize;
              const y = Math.floor(index / gridSize) * cellSize;

              let fillColor;
              let pixlidx = pixels[index];
              if (pixlidx !== null && pixlidx !== undefined) {
                fillColor = palette[pixlidx];
              } else {
                fillColor = !showBackground ? 'white' : 'transparent';
              }

              return (
                <rect
                  key={index}
                  x={x}
                  y={y}
                  width={cellSize}
                  height={cellSize}
                  fill={fillColor}
                  stroke={showGrid ? (showBackground ? 'white' : 'darkgrey') : ''}
                  onClick={() => handlePixelClick(index)}
                  onMouseDown={() => handleMouseDown(index)}
                  onTouchStart={() => handleMouseDown(index)}
                  onTouchEnd={handleMouseUp}
                  onMouseUp={handleMouseUp}
                  onMouseOver={() => handleMouseOver(index)}
                />
              );
            })}

          </g>
        </g>
      </svg>
    </div>
  );
};

export default React.memo(PixelGrid);
