import React, { useEffect, useState } from 'react';
import { backgroundColors } from '../constants/backgroundColors';

interface Palette {
    id: number;
    name: string;
    colors: string[];
}

interface SettingsProps {
    palettes: Palette[];
    setSelectedPaletteId: Function;
    selectedPaletteId: number;
    showPixelGrid: boolean;
    setShowPixelGrid: (value: boolean) => void;
    gridSize: number;
    setGridSize: (value: number) => void;
    showBackground: boolean;
    setShowBackground: (value: boolean) => void;
    backgroundColor: string;
    setBackgroundColor: (color: string) => void;
}

const SettingsToolbar: React.FC<SettingsProps> = ({
    palettes, setSelectedPaletteId, selectedPaletteId,
    showPixelGrid, setShowPixelGrid,
    gridSize, setGridSize,
    showBackground, setShowBackground,
    backgroundColor, setBackgroundColor
}) => {

    const [localShowPixelGrid, setLocalShowPixelGrid] = useState(showPixelGrid);
    const [localGridSize, setLocalGridSize] = useState(gridSize);
    const [localShowBackground, setLocalShowBackground] = useState(showBackground);
    const [localBackgroundColor, setLocalBackgroundColor] = useState(backgroundColor);
    const [localId, setLocalId] = useState(selectedPaletteId);

    useEffect(() => {
        setLocalShowPixelGrid(showPixelGrid);
        setLocalGridSize(gridSize);
        setLocalShowBackground(showBackground);
        setLocalBackgroundColor(backgroundColor);
        setLocalId(selectedPaletteId);
    }, [showPixelGrid, gridSize, showBackground, backgroundColor, selectedPaletteId]);

    const handlePaletteClick = (palette: Palette) => {
        setSelectedPaletteId(palette.id);
        setLocalId(palette.id);
    };

    return (
        <div className='hidden pnt:flex'>
            <div className={`absolute right-2 pnt2:right-14 top-3 bg-white w-32 border border-darkgrey drop-shadow rounded p-2`}>
                <h2 className="text-lg font-semibold text-center">Palette Selection</h2>
                <div className="flex flex-wrap justify-between w-full mb-1">
                    {palettes.map((palette) => (
                        <button
                            key={palette.id}
                            className={`w-full text-left pl-1 flex flex-row whitespace-nowrap ${localId === palette.id ? 'active text-cyan-500' : ''}`}
                            onClick={() => handlePaletteClick(palette)}
                        >
                            {palette.name}
                        </button>
                    ))}
                </div>
                <h2 className="text-lg font-semibold text-center">Grid Settings</h2>
                <div className="flex flex-wrap flex-col justify-between w-full mb-2">
                    <div className='flex flex-row'>
                        {[16, 24, 32].map((size) => (
                            <button
                                key={size}
                                className={`w-full text-left pl-1 flex flex-row whitespace-nowrap ${localGridSize === size ? 'active text-cyan-500' : ''}`}
                                onClick={() => { setLocalGridSize(size); setGridSize(size); }}
                            >
                                {`${size}px`}
                            </button>
                        ))}
                    </div>
                    <label className="flex items-center cursor-pointer">
                        <input type="checkbox" className="form-checkbox hover:cursor-pointer" checked={localShowPixelGrid} onChange={() => { setLocalShowPixelGrid(!localShowPixelGrid); setShowPixelGrid(!localShowPixelGrid); }} />
                        <span className="ml-2">Show Pixel Grid</span>
                    </label>
                </div>

                <h2 className="text-lg font-semibold text-center">PFP Mode</h2>
                <div className="flex flex-wrap justify-between w-full mb-4">
                    <label className="flex items-center cursor-pointer whitespace-nowrap">
                        <input type="checkbox" className="form-checkbox hover:cursor-pointer" checked={localShowBackground} onChange={() => { setLocalShowBackground(!localShowBackground); setShowBackground(!localShowBackground); }} />
                        <span className="ml-1">Enable PFP Mode</span>
                    </label>
                    <h5 className='text-center ml-1'>Background Color:</h5>
                    <div className='flex flex-wrap justify-center gap-2 mt-1'>
                    {backgroundColors.map((color, index) => (
                        <div
                            key={index}
                            className={`h-4 w-4 rounded-sm border-2 hover:cursor-pointer drop-shadow-lg ${localBackgroundColor === color ? 'border-gray-300' : 'border-transparent'}`}
                            style={{ backgroundColor: color }}
                            onClick={() => { setLocalBackgroundColor(color); setBackgroundColor(color); }}
                        ></div>
                    ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsToolbar;
