import React, { useState } from 'react';
import { Painting } from './savePainting';

interface PaintingFileHandlerProps {
  setPaletteId: React.Dispatch<React.SetStateAction<number>>;
  setPixels: (newPixels: Array<number | null>) => void;
  setGridSize: React.Dispatch<React.SetStateAction<number>>;
  setHasBackground: React.Dispatch<React.SetStateAction<boolean>>;
  setBackgroundColor: React.Dispatch<React.SetStateAction<string>>;
  paletteId: number;
  pixels: Array<number | null>;
  gridSize: number;
  hasBackground: boolean;
  backgroundColor: string;
}

const PaintingFileHandler: React.FC<PaintingFileHandlerProps> = (props) => {
  const { setPaletteId, setPixels, setGridSize, setHasBackground, setBackgroundColor, paletteId, pixels, gridSize, hasBackground, backgroundColor } = props;
  const [fileName, setFileName] = useState("masterpiece");

  const loadPainting = (painting: Painting) => {
    setPaletteId(painting.paletteId);
    setGridSize(painting.gridSize);
    setPixels(painting.pixels);
    setHasBackground(painting.hasBackground);
    setBackgroundColor(painting.backgroundColor);
  };

  const saveCurrentPainting = () => {
    const newPainting: Painting = {
      date: new Date().toLocaleString(),
      gridSize,
      paletteId,
      pixels,
      hasBackground,
      backgroundColor,
    };
    const blob = new Blob([JSON.stringify(newPainting)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName || 'masterpiece'}-${newPainting.date}.json`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const loadFromJSONFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as string;
        const loadedPainting: Painting = JSON.parse(content);
        loadPainting(loadedPainting);
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="p-4 max-h-128 flex flex-col justify-center items-center">
      {/* Download Card */}
      <div className="bg-blue-100 p-4 rounded-lg shadow-md mb-3 w-1/2">
        <h3 className="text-lg font-semibold mb-2">Download Painting</h3>
        <p className="text-sm mb-2">
          Save your current painting to a JSON file.
        </p>
        <input 
          type="text" 
          placeholder="File Name (Optional)" 
          className="mb-2 p-1 border rounded"
          onChange={(e) => setFileName(e.target.value)}
        />
        <button className="bg-blue-500 text-white p-2 rounded w-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" onClick={saveCurrentPainting}>
          Download
        </button>
      </div>

      {/* Upload Card */}
      <div className="bg-green-100 p-4 rounded-lg shadow-md w-1/2">
        <h3 className="text-lg font-semibold mb-2">Upload Painting</h3>
        <p className="text-sm mb-2">
          Load a painting from a JSON file.
        </p>
        {/* <input type="file" accept=".json" className="bg-green-500 text-white p-1 rounded" onChange={loadFromJSONFile} /> */}
        <input type="file" accept=".json" className="bg-green-500 text-white p-1 rounded w-full overflow-hidden truncate" onChange={loadFromJSONFile} />

      </div>
    </div>
  );
};

export default PaintingFileHandler;
