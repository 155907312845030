import React from 'react';

interface IconImageProps {
  imageSrc: string;
  size: number; // Size in pixels
  altText?: string; // Optional alt text for accessibility
  color?: string; // Optional color
  invert?: boolean; // Optional invert color
  className?: string;
  onClick?: Function;
}

const IconImage: React.FC<IconImageProps> = ({ imageSrc, size, altText = '', color, invert, className, onClick }) => {
  const filters = [];
  if (color) {
    filters.push(`hue-rotate(${color})`);
  }
  if (invert) {
    filters.push('invert(1)');
  }
  const filterStyle = filters.join(' ');

  return (
    <img
      src={imageSrc}
      alt={altText}
      className={`object-cover ${className}`}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        filter: filterStyle
      }}
      onClick={() => {if (onClick) onClick()}}
    />
  );
};

export default IconImage;
