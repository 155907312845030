import { useState } from 'react';

export const useUndoRedo = <T>(initialState: T) => {
  const [past, setPast] = useState<T[]>([]);
  const [present, setPresent] = useState<T>(initialState);
  const [future, setFuture] = useState<T[]>([]);

  // Deep comparison function
  const deepEqual = (obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }

    // Handle array comparison
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      if (obj1.length !== obj2.length) return false;
      for (let i = 0; i < obj1.length; i++) {
        if (!deepEqual(obj1[i], obj2[i])) return false;
      }
      return true;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  const undo = () => {
    if (past.length === 0) return;

    const newPresent = past[past.length - 1];
    const newPast = past.slice(0, past.length - 1);

    setFuture([present, ...future]);
    setPast(newPast);
    setPresent(newPresent);
  };

  const redo = () => {
    if (future.length === 0) return;

    const newPresent = future[0];
    const newFuture = future.slice(1);

    setPast([...past, present]);
    setFuture(newFuture);
    setPresent(newPresent);
  };

  const set = (newPresent: T) => {
    if (deepEqual(newPresent, present)) return;

    // Check if the new state is already in the past using deep comparison
    if (!deepEqual(past[past.length - 1], present)) {
      setPast([...past, present]);
    }
    setPresent(newPresent);
    setFuture([]);
  };

  return { set, undo, redo, present };
};
