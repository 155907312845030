import React, { useState } from 'react';
import Tool, { getIconByTool, specialTools, standardTools } from '../constants/toolTypes';
import IconImage from './iconImage';

interface ToolSelectorProps {
    selectedTool: Tool;
    setSelectedTool: React.Dispatch<React.SetStateAction<Tool>>;
}

const ToolSelector: React.FC<ToolSelectorProps> = ({ selectedTool, setSelectedTool }) => {
    const [_selectedTool, _setSelectedTool] = useState<Tool>(selectedTool);

    const setTool = (selection: Tool) => {
        setSelectedTool(selection);
        _setSelectedTool(selection);
    }

    return (
        <div className="flex flex-col items-center justify-center space-y-4">
            <h2 className="text-lg font-semibold">Select Tool</h2>
            <div className="flex flex-row space-x-4">
                <div className="flex flex-col items-center space-y-2 w-full">
                    <span className="font-semibold">Standard</span>
                    {standardTools.map((tool, index) => (
                        <button
                            key={index}
                            className={`flex items-center justify-center w-40 py-1 text-lg rounded ${_selectedTool === tool ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
                            onClick={() => setTool(tool)}
                        >
                            <IconImage imageSrc={getIconByTool(tool)} size={tool === 'Small Brush' ? 14 : tool === "Medium Brush" ? 17 : 20} />
                            <span className="ml-2">{tool}</span>
                        </button>
                    ))}
                </div>
                <div className="flex flex-col items-center space-y-2 w-full">
                    <span className="font-semibold">Special</span>
                    {specialTools.map((tool, index) => (
                        <button
                            key={index}
                            className={`flex items-center justify-center w-40 py-1 text-lg rounded ${_selectedTool === tool ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
                            onClick={() => setTool(tool)}
                        >
                            <IconImage imageSrc={getIconByTool(tool)} size={20} />
                            <span className="ml-2">{tool}</span>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ToolSelector;
