import React, { useState, useEffect } from 'react';

interface ShadowCursorProps {
  parentRef: React.RefObject<HTMLDivElement>;
}

const ShadowCursor: React.FC<ShadowCursorProps> = ({ parentRef }) => {
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const [isInside, setIsInside] = useState(false);

  const handleMouseMove = (e: MouseEvent) => {
    if (parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      const squareWidth = rect.height; // Assuming the painting space is a square
  
      // Calculate the left and right boundaries for the centered square
      const leftBoundary = rect.left + (rect.width - squareWidth) / 2;
      const rightBoundary = rect.left + (rect.width + squareWidth) / 2;
  
      const inside =
        e.clientX >= leftBoundary &&
        e.clientX <= rightBoundary &&
        e.clientY >= rect.top &&
        e.clientY <= rect.bottom;
  
      setIsInside(inside);
  
      if (inside) {
        setCursorPos({
          x: e.clientX - rect.left,
          y: e.clientY - rect.top,
        });
      }
    }
  };
    
  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return isInside ? (
    <div
      style={{
        position: 'absolute',
        top: `${cursorPos.y - 18}px`,
        left: `${cursorPos.x - 8}px`,
        width: '26px',
        height: '26px',
        borderRadius: '50%',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        boxShadow: '0 0 15px rgba(255, 255, 255, 0.1)',
        pointerEvents: 'none',
        zIndex: 9999,
      }}
    ></div>
  ) : null;
};

export default ShadowCursor;
