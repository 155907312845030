import React, { useEffect, useState } from "react";
import { useAudioContext } from "./audioContextProvider";
import lowrider from "./songs/lowrider.mp3";
import outaspace from "./songs/outaspace.mp3";
import hangUp from "./songs/Hang Up Your Hang Ups.mp3";
import playIcon from "../images/play.png"; // Replace with your own SVG icon
import stopIcon from "../images/stop.png"; // Replace with your own SVG icon

const songs = [
  { title: "Low Rider", url: lowrider },
  { title: "Outa Space", url: outaspace },
  { title: "Hang Up Your Hang Ups", url: hangUp}
];

interface ISongSelector {
  bubbles: boolean;
  setBubbles: Function;
}

const SongSelector: React.FC<ISongSelector> = ({ bubbles, setBubbles }) => {
  const { audioContext, playSong, stopSong, currentSong, setCurrentSong } = useAudioContext();


  useEffect(() => {
    // This will fetch the `currentSong` from your audioContext when this component mounts
    if (currentSong) {
      setCurrentSong(currentSong);
    }
  }, []);



  const handleSongSelection = async (songUrl: string, title: string) => {
    if (audioContext?.state === "suspended") {
      await audioContext.resume();
    }

    playSong(songUrl, title);
    setCurrentSong(title);
  };

  const handleStop = () => {
    stopSong();
    setCurrentSong(null);
  };

  return (
    <div className="p-6">
      <h3 className="text-2xl mb-4">Select a Song</h3>
      {currentSong && (
        <div className="mb-4 flex items-center">
          <span className="text-xl flex-1">Now Playing: {currentSong}</span>
          <button onClick={handleStop} className="px-4 py-2 bg-red-500 text-white rounded">
            <img src={stopIcon} alt="Stop" className="w-5 h-5 inline-block mr-2" />
            Stop
          </button>
        </div>
      )}
      <ul className="space-y-2">
        {songs.map((song, index) => (
          <li key={index} className="flex items-center">
            <span className="flex-1 text-lg">{song.title}</span>
            <button
              onClick={() => handleSongSelection(song.url, song.title)}
              className="px-4 py-2 bg-blue-500 text-white rounded text-lg"
            >
              <img src={playIcon} alt="Play" className="w-5 h-5 inline-block mr-2" />
              Play
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SongSelector;
