import React from 'react';
import { Painting, renderThumbnail } from './savePainting';

interface LoadTemplateProps {
  paintings: Painting[];
  palettes: any;
  onLoadPainting: (painting: Painting) => void;
}

const titles = ["Bear", "Female Punk", "Male Punk", "Toad", "Shroom", "Fire Flower", "Star", "Kong"]

const LoadTemplate: React.FC<LoadTemplateProps> = ({ paintings, palettes, onLoadPainting }) => {
  return (
    <div>
      <h2 className="text-center mb-4">Load Template</h2>
      <ul className="flex flex-col items-center gap-4">
        {paintings.map((painting, index) => (
          <li 
            key={index} 
            className="flex flex-col justify-between items-center border-b py-2 cursor-pointer hover:bg-gray-200"
            onClick={() => onLoadPainting(painting)}
          >
            <span>
              {renderThumbnail(painting, palettes)}
            </span>
            <div className="mt-2">
              {titles[index]}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LoadTemplate;
