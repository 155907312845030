import React, { useState } from 'react';

interface Palette {
  id: number;
  name: string;
  colors: string[];
}

interface PaletteSelectorProps {
  palettes: Palette[];
  setSelectedPaletteId: Function;
  selectedPaletteId: number;
}

const PaletteSelector: React.FC<PaletteSelectorProps> = ({ palettes, setSelectedPaletteId, selectedPaletteId }) => {

  const [colorsToRender, setColorsToRender] = useState(palettes[selectedPaletteId - 1].colors);
  const [localId, setLocalId] = useState(selectedPaletteId);


  const handlePaletteClick = (palette: Palette) => {
    setSelectedPaletteId(palette.id);
    setLocalId(palette.id);
    setColorsToRender(palette.colors);
  };

  const getPaletteButtons = (selectedPaletteId: number) => {
    return palettes.map((palette) => (
      <button
        key={palette.id}
        className={`p-2 rounded border ${localId === palette.id ? 'bg-blue-500 text-white' : ''}`}
        onClick={() => {handlePaletteClick(palette)}}
      >
        {palette.name}
      </button>
    ));
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-2 text-center">Select a Palette</h2>
      <div className="flex flex-wrap justify-center gap-2 mb-2">
      {
        getPaletteButtons(selectedPaletteId)
      }
      </div>
      {colorsToRender && (
        <div className="flex flex-wrap justify-center gap-2">
          {colorsToRender.map((color, index) => (
            <div key={index} className="h-8 w-8 rounded-md" style={{ backgroundColor: color }}></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PaletteSelector;
