import React, { useState, useEffect } from 'react';
import { backgroundColors } from '../constants/backgroundColors';

interface SettingsProps {
  showPixelGrid: boolean;
  setShowPixelGrid: (value: boolean) => void;
  gridSize: number;
  setGridSize: (value: number) => void;
  showBackground: boolean;
  setShowBackground: (value: boolean) => void;
  backgroundColor: string;
  setBackgroundColor: (color: string) => void;
}

const Settings: React.FC<SettingsProps> = ({ 
  showPixelGrid, setShowPixelGrid, 
  gridSize, setGridSize, 
  showBackground, setShowBackground, 
  backgroundColor, setBackgroundColor 
}) => {

  const [localShowPixelGrid, setLocalShowPixelGrid] = useState(showPixelGrid);
  const [localGridSize, setLocalGridSize] = useState(gridSize);
  const [localShowBackground, setLocalShowBackground] = useState(showBackground);
  const [localBackgroundColor, setLocalBackgroundColor] = useState(backgroundColor);

  useEffect(() => {
    setLocalShowPixelGrid(showPixelGrid);
    setLocalGridSize(gridSize);
    setLocalShowBackground(showBackground);
    setLocalBackgroundColor(backgroundColor);
  }, [showPixelGrid, gridSize, showBackground, backgroundColor]);


  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4 text-center">Settings</h2>
      
      {/* Grid Settings */}
      <div className="mb-6">
        {/* <h3 className="text-lg font-semibold mb-2 text-center">Grid</h3> */}
        <div className="flex flex-col items-center">
          <label className="flex items-center cursor-pointer">
            <input type="checkbox" className="form-checkbox" checked={localShowPixelGrid} onChange={() => { setLocalShowPixelGrid(!localShowPixelGrid); setShowPixelGrid(!localShowPixelGrid); }} />
            <span className="ml-2">Show Pixel Grid</span>
          </label>
        </div>
        <div className="flex flex-col items-center mt-2">
          <span className="text-md font-semibold">Grid Size</span> 
          <div className="flex flex-wrap justify-center gap-2 mt-2">
            {[16, 24, 32].map((size) => (
              <button
                key={size}
                className={`p-2 rounded border ${localGridSize === size ? 'bg-blue-500 text-white' : ''}`}
                onClick={() => { setLocalGridSize(size); setGridSize(size); }}
              >
                {`${size}px`}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Background Settings */}
      <div>
  {/* <h3 className="text-lg font-semibold mb-2 text-center">PFP Mode</h3> */}
  <div className="flex flex-col items-center">
    <label className="flex items-center cursor-pointer">
      <input type="checkbox" className="form-checkbox" checked={localShowBackground} onChange={() => { setLocalShowBackground(!localShowBackground); setShowBackground(!localShowBackground); }} />
      <span className="ml-2">PFP Mode</span>
    </label>
  </div>
  <div className="flex flex-col items-center mt-2">
    <span className="text-md font-semibold mb-1">Background Color (PFP Mode) </span> 
    <div className="flex flex-wrap justify-center gap-2">
      {backgroundColors.map((color) => (
        <button
          key={color}
          className={`p-2 w-8 h-8 rounded border ${localBackgroundColor === color ? 'ring-2 ring-gray-300' : ''}`}
          style={{ backgroundColor: color }}
          onClick={() => { setLocalBackgroundColor(color); setBackgroundColor(color); }}
        >
        </button>
      ))}
    </div>
  </div>
</div>

    </div>
  );
};

export default Settings;
