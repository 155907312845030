import brushImg from '../images/pointerbrush.png';
import bucketImg from '../images/pointerBucket.png';
import starIcon from '../images/star.png';
import diamond from '../images/diamond.png';
import square from '../images/squareIcon.png';
import circle from '../images/circleIcon.png';
import splatter from '../images/splatter.png';
import Tool from './toolTypes';


const getCustomCursorStyle = (selectedTool: Tool): React.CSSProperties => {
  let cursorImage: string;

  switch (selectedTool) {
    case 'Small Brush':
      cursorImage = brushImg;
      break;
    case 'Medium Brush':
      cursorImage = brushImg;
      break;
    case 'Large Brush':
      cursorImage = brushImg;
      break;
    case 'Diamond Stamp':
      cursorImage = diamond;
      break;
    case 'Star Stamp':
      cursorImage = starIcon;
      break;
    case 'Square Stamp':
      cursorImage = square;
      break;
    case 'Circle Stamp':
      cursorImage = circle;
      break;
    case 'Bucket':
      cursorImage = bucketImg;
      break;
    case 'Splatter':
      cursorImage = splatter;
      break;
    default:
      cursorImage = 'auto';
  }

  return {
    cursor: `url('${cursorImage}') 8 16, auto`,
  };
};

export default getCustomCursorStyle;
