import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import palettes from '../constants/palettes';

export interface Painting {
  date: string;
  gridSize: number;
  paletteId: number;
  pixels: Array<number | null>;
  hasBackground: boolean;
  backgroundColor: string;
}

interface SaveLoadPaintingProps {
  setPaletteId: React.Dispatch<React.SetStateAction<number>>;
  setPixels: (newPixels: Array<number | null>) => void;
  setGridSize: React.Dispatch<React.SetStateAction<number>>;
  setHasBackground: React.Dispatch<React.SetStateAction<boolean>>;
  setBackgroundColor: React.Dispatch<React.SetStateAction<string>>;
  paletteId: number;
  pixels: Array<number | null>;
  gridSize: number;
  hasBackground: boolean;
  backgroundColor: string;
}

export const renderThumbnail = (painting: Painting, palettes: any) => {
  const { gridSize, pixels, paletteId, hasBackground, backgroundColor } = painting;
  const palette = palettes[paletteId - 1].colors;
  const padding = 1;
  const bgOffset = 1.15;
  const cellSize = 10;
  const svgSize = gridSize * cellSize;
  const backgroundSize = svgSize * bgOffset;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="crispEdges" width={hasBackground ? backgroundSize : svgSize} height={hasBackground ? backgroundSize : svgSize}>
      {hasBackground && (
        <rect
          x={padding}
          y={padding}
          width={backgroundSize - 2 * padding}
          height={backgroundSize - 2 * padding}
          fill={backgroundColor}
        />
      )}
      <g transform={`translate(${hasBackground ? (backgroundSize - svgSize) / 2 : 0}, ${hasBackground ? padding * 2 : 0})`}>
        <g transform={`translate(${padding}, ${padding + (hasBackground ? ((backgroundSize - svgSize - 2 * padding) - 2) : 0)})`}>
          {pixels.map((colorIndex, index) => {
            const x = (index % gridSize) * cellSize;
            const y = Math.floor(index / gridSize) * cellSize;

            return (
              <rect
                key={index}
                x={x}
                y={y}
                width={cellSize}
                height={cellSize}
                fill={colorIndex !== null ? palette[colorIndex] : (hasBackground ? 'transparent' : '#FFFFFF')}
              />
            );
          })}
        </g>
      </g>
    </svg>
  );
};


const SaveLoadPainting: React.FC<SaveLoadPaintingProps> = ({ setPaletteId, setPixels, setGridSize, paletteId, pixels, gridSize, hasBackground, setHasBackground, backgroundColor, setBackgroundColor }) => {
  const [savedPaintings, setSavedPaintings] = useState<Painting[]>([]);

  const downloadSVG = (painting: Painting) => {
    const svgString = ReactDOMServer.renderToStaticMarkup(renderThumbnail(painting, palettes));
    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `masterpiece-${painting.date}.svg`;
    link.click();
  };

  const downloadPNG = (painting: Painting) => {
    const svgString = ReactDOMServer.renderToStaticMarkup(renderThumbnail(painting, palettes));
    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);

    const img = new Image();
    img.src = url;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx!.drawImage(img, 0, 0);
      const pngDataUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = pngDataUrl;
      link.download = `masterpiece-${painting.date}.png`;
      link.click();

      // Clean up the object URL to free memory
      URL.revokeObjectURL(url);
    };
  };


  useEffect(() => {
    const storedPaintings = localStorage.getItem('savedPaintings');
    if (storedPaintings) {
      setSavedPaintings(JSON.parse(storedPaintings));
    }
  }, []);

  const saveCurrentPainting = () => {
    const newPainting: Painting = {
      date: new Date().toLocaleString(),
      gridSize,
      paletteId,
      pixels,
      hasBackground,
      backgroundColor,
    };

    const newPaintings = [...savedPaintings, newPainting];
    setSavedPaintings(newPaintings);
    localStorage.setItem('savedPaintings', JSON.stringify(newPaintings));
  };

  const loadPainting = (painting: Painting) => {
    setPaletteId(painting.paletteId);
    setGridSize(painting.gridSize);
    setPixels(painting.pixels);
    setHasBackground(painting.hasBackground);
    setBackgroundColor(painting.backgroundColor);
  };

  const deletePainting = (index: number) => {
    const updatedPaintings = [...savedPaintings];
    updatedPaintings.splice(index, 1);
    setSavedPaintings(updatedPaintings);
    localStorage.setItem('savedPaintings', JSON.stringify(updatedPaintings));
  };

  return (
    <div className="p-4 max-h-128">
      <div className='flex flex-col justify-center items-center'>
        <div className="bg-white p-4 rounded-lg shadow-md w-72 ">
          <h3 className="text-lg font-semibold mb-2">Save Your Work</h3>
          <p className="text-sm mb-2">
            Click this button to save your current work and reload it later. This interaction simply saves the current image and settings in your browser. No gas or ETH transactions are needed. If you clear your browsing data, specifically application storage, you will lose your saved paintings. You can download your saved paintings in SVG or PNG format.
          </p>
          <button className="bg-blue-500 text-white p-2 rounded w-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" onClick={saveCurrentPainting}>
            Save Current Painting
          </button>
        </div>
      </div>
      <div className="mt-4">
        <h2 className="text-lg font-semibold">Saved Paintings</h2>
        <ul className="mt-2">
          {savedPaintings.map((painting, index) => (
            <li key={index} className="flex justify-between items-center border-b py-2">
              <div className="flex flex-col">
                <span>
                  {renderThumbnail(painting, palettes)}
                </span>
                <div className="text-sm mt-2">
                  <span>Date: {painting.date}</span><br />
                  <span>Grid Size: {painting.gridSize}</span>
                </div>
                <div className="flex space-x-2 mb-2">
                  <button className="bg-green-500 text-white p-1 rounded w-28 text-center" onClick={() => loadPainting(painting)}>
                    Load
                  </button>
                  <button className="bg-red-500 text-white p-1 rounded w-28 text-center" onClick={() => deletePainting(index)}>
                    Delete
                  </button>
                </div>
                <div className="flex space-x-2">
                  <button className="bg-blue-500 text-white p-1 rounded w-28 text-center" onClick={() => downloadSVG(painting)}>
                    Download SVG
                  </button>
                  <button className="bg-blue-500 text-white p-1 rounded w-28 text-center" onClick={() => downloadPNG(painting)}>
                    Download PNG
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

};

export default SaveLoadPainting;
