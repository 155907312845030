import React, { useEffect, useState } from 'react';
import brushIcon from '../images/iconBrush.png';
import bucketIcon from '../images/iconBucket.png';
import eraserIcon from '../images/eraser.png';
import paletteIcon from '../images/paletteIcon.png';
import IconImage from './iconImage';
import Tool, { getIconByTool } from '../constants/toolTypes';
import NFTPaintComponent from './info';
import appConfig from '../config';

interface MenuOption {
  label: string;
  callback: () => void;
  icon?: JSX.Element;
}

interface NavBarProps {
  menuOptions: MenuOption[];
  mobileMenuOptions: MenuOption[];
  className: string;
  bgColor: string;
  selectedColor: string | null;
  selectedTool: string;
  openModalWithTabs: Function;
}

const NavBar: React.FC<NavBarProps> = ({ menuOptions, mobileMenuOptions, className, bgColor, selectedColor, selectedTool, openModalWithTabs }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && event.target instanceof Node && !document.querySelector('.absolute')?.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (callback: () => void) => {
    callback();
    setIsOpen(false);
  };

  return (
    <nav id='navbar' className={`h-16 p-4 flex items-center justify-between ${className}`} style={{ backgroundColor: bgColor }}>
      <div onClick={() => {openModalWithTabs([{name: "NFT Paint", component: <NFTPaintComponent 
            pixelmageTwitterLink="https://twitter.com/pixelmage69"
            bearsDeluxeTwitterLink="https://twitter.com/bears_deluxe"
            openseaLink="https://opensea.io/collection/nft-paint-deluxe"
            etherscanLink={`https://etherscan.io/address/${appConfig.paintAddress}`}
      />}])}} className="flex items-center text-white text-5xl hover:cursor-pointer">
        <IconImage imageSrc={paletteIcon} size={40} />
        <span className="ml-3">NFT Paint</span>
      </div>
      <div className="ml-auto flex items-center">
        <div className="pnt:hidden">
          <button className="text-white text-5xl" onClick={toggleDropdown}>
            Menu
          </button>
        </div>
        <div className="hidden pnt:flex">
          {menuOptions.map((option, index) => (
            <button key={index} className="flex items-center text-white p-4 text-2xl" onClick={() => handleMenuClick(option.callback)}>
              {
                option.label === "Tool" &&
                <IconImage className='mr-2' imageSrc={getIconByTool(selectedTool as Tool)} invert={true} size={20} />
              }
              {
                option.label === "Color" &&
                <>
                  {selectedColor ? <div className='mr-2.5' style={{ backgroundColor: selectedColor, width: '20px', height: '20px' }}></div> : <IconImage imageSrc={eraserIcon} size={22} invert={true} className='mr-2.5' />}
                </>
              }
              {option.label}
            </button>
          ))}
        </div>
        <div className={`absolute right-9 top-12 mt-2 w-48 rounded-md shadow-lg bg-white z-10 ${isOpen ? 'block' : 'hidden'}`} onBlur={() => setIsOpen(false)}>
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {mobileMenuOptions.map((option, index) => (
              <button key={index} className="flex items-center block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" onClick={() => handleMenuClick(option.callback)}>
                {option.label}
                {
                  option.label === "Tool" &&
                  <IconImage className='ml-2' imageSrc={getIconByTool(selectedTool as Tool)} invert={false} size={20} />
                }
                {
                  option.label === "Color" &&
                  <>
                    {selectedColor ? <div className='ml-2' style={{ backgroundColor: selectedColor, width: '20px', height: '20px' }}></div> : <IconImage imageSrc={eraserIcon} size={22} invert={false} className='ml-2' />}
                  </>
                }
              </button>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
