import './App.css';
import React, { useEffect, useState } from 'react';
import { WagmiConfig } from 'wagmi';
import wagmiConf from './web3/walletConfig';
import NavBar from './components/navbar';
import DynamicModal from './components/dynamicModal';
import { Profile } from './web3/connect';
import PaletteSelector from './components/paletteSelector';
import palettes from './constants/palettes';
import Settings from './components/settings';
import PixelGrid from './components/pixelGrid';
import ColorSelector from './components/colorSelector';
import Tool from './constants/toolTypes';
import ToolSelector from './components/toolSelector';
import { useUndoRedo } from './constants/undoRedo';
import SaveLoadPainting, { Painting } from './components/savePainting';
import { backgroundColors } from './constants/backgroundColors';
import { AudioContextProvider } from './audio/audioContextProvider';
import SongSelector from './audio/songSelector';
import Visualizer from './audio/visualizer';
import LoadTemplate from './components/loadTemplate';
import { templates } from './constants/templates';
import { MintPainting } from './components/mintPainting';
import Toolbar from './components/toolbar';
import { LoadRemix } from './web3/loadRemix';
import SettingsToolbar from './components/settingsToolbar';
import PaintingFileHandler from './components/saveLoadFiles';
import ImageUploader from './components/imageTracing/imageUploader';
import { TracingProvider } from './components/imageTracing/tracingContext';

function App() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<Array<{ name: string, component: React.ReactNode }>>([]);
  const [selectedPalette, setSelectedPalette] = useState<number>(1);
  const [showPixelGrid, setShowPixelGrid] = useState<boolean>(false);
  const [gridSize, setGridSize] = useState<number>(24);
  const [selectedColor, setSelectedColor] = useState<number | null>(0);
  const [background, setBackground] = useState<boolean>(false);
  const [backgroundColor, setBackgroundColor] = useState<string>(backgroundColors[0]);
  const [selectedTool, setSelectedTool] = useState<Tool>('Small Brush');
  const [themeColorPrimary, setThemeColorPrimary] = useState<string>("#1f2937");
  const [bubbles, setBubbles] = useState(false);

  const { set: setPixelsHistory, undo, redo, present: presentPixels } = useUndoRedo<Array<number | null>>([]);


  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {

      if (e.ctrlKey && e.key === 'r') {
        redo(); // Ctrl + R
      } else if (e.ctrlKey && e.key === 'z') {
        undo(); // Ctrl + Z
      } else if (e.key === 'g') {
        setSelectedTool('Bucket'); // G
      } else if (e.key === 'b') {
        setSelectedTool('Small Brush'); // B
      } else if (e.key === 's') {
        setSelectedTool('Splatter'); // S
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [undo, redo, setSelectedTool]);


  const handleSetPixels = (newPixels: Array<number | null>) => {
    setPixelsHistory(newPixels);
  };

  const openModalWithTabs = (tabs: Array<{ name: string, component: React.ReactNode }>) => {
    setModalContent(tabs);
    setModalOpen(true);
  };

  const getProductionGrid = () => {
    const prodGrid: number[] = [];
    for (let i = 0; i < (gridSize * gridSize); i++) {
      prodGrid.push((presentPixels[i] !== null && presentPixels[i] !== undefined) ? presentPixels[i] as number : 129);
    };
    return prodGrid;
  }

  const menuOptions = [
    {
      label: 'Template', callback: () => {
        openModalWithTabs([{
          name: 'Load Template', component: <LoadTemplate paintings={templates} palettes={palettes} onLoadPainting={(painting: Painting) => {
            setGridSize(painting.gridSize);
            handleSetPixels(painting.pixels);
            setSelectedPalette(painting.paletteId);
            setBackground(painting.hasBackground);
            setBackgroundColor(painting.backgroundColor);
          }} />
        },
        {
          name: 'Remix',
          component: <LoadRemix setPixels={handleSetPixels} setBackground={setBackground} setBackgroundColor={setBackgroundColor} setGridSize={setGridSize} setPalette={setSelectedPalette} />
        },
        {
          name: 'Trace',
          component: <ImageUploader />
        }
        ])
      }
    },
    { label: 'Mint', callback: () => openModalWithTabs([{ name: 'Mint', component: <MintPainting pixels={getProductionGrid()} backgroundColor={background ? "0x" + backgroundColor.slice(1) : '0x00000'} paletteId={selectedPalette} /> }, { name: 'Connect', component: <Profile /> }]) },
    { label: "Music", callback: () => openModalWithTabs([{ name: 'Music', component: <SongSelector bubbles={bubbles} setBubbles={setBubbles} /> }]) },
    {
      label: "Save/Load", callback: () => {
        openModalWithTabs([{ name: 'Save/Load', component: <SaveLoadPainting hasBackground={background} setHasBackground={setBackground} backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} setPaletteId={setSelectedPalette} setPixels={handleSetPixels} setGridSize={setGridSize} paletteId={selectedPalette} pixels={presentPixels} gridSize={gridSize} /> }, {
          name: "Import/Export", component: <PaintingFileHandler setPaletteId={setSelectedPalette} setPixels={handleSetPixels} setGridSize={setGridSize} setHasBackground={setBackground} setBackgroundColor={setBackgroundColor} paletteId={selectedPalette} pixels={presentPixels} gridSize={gridSize} hasBackground={background} backgroundColor={backgroundColor}
          />
        }])
      }
    },
  ];

  const mobileMenuOptions = [
    { label: 'Tool', callback: () => openModalWithTabs([{ name: 'Tool', component: <ToolSelector selectedTool={selectedTool} setSelectedTool={setSelectedTool} /> }]) },
    { label: 'Color', callback: () => openModalWithTabs([{ name: 'Color', component: <ColorSelector selectedColor={selectedColor} setSelectedColor={setSelectedColor} palette={palettes[selectedPalette - 1].colors} /> }]) },
    { label: 'Undo', callback: undo },
    { label: 'Redo', callback: redo },
    {
      label: 'Template', callback: () => {
        openModalWithTabs([{
          name: 'Load Template', component: <LoadTemplate paintings={templates} palettes={palettes} onLoadPainting={(painting: Painting) => {
            setGridSize(painting.gridSize);
            handleSetPixels(painting.pixels);
            setSelectedPalette(painting.paletteId);
            setBackground(painting.hasBackground);
            setBackgroundColor(painting.backgroundColor);
          }} />
        },
        {
          name: 'Remix',
          component: <LoadRemix setPixels={handleSetPixels} setBackground={setBackground} setBackgroundColor={setBackgroundColor} setGridSize={setGridSize} setPalette={setSelectedPalette} />
        },
        {
          name: 'Trace',
          component: <ImageUploader />
        }])
      }
    },
    { label: 'Mint', callback: () => openModalWithTabs([{ name: 'Mint', component: <MintPainting pixels={getProductionGrid()} backgroundColor={background ? "0x" + backgroundColor.slice(1) : '0x00000'} paletteId={selectedPalette} /> }, { name: 'Connect', component: <Profile /> }]) },
    { label: 'Settings', callback: () => openModalWithTabs([{ name: 'Palette', component: <PaletteSelector palettes={palettes} setSelectedPaletteId={setSelectedPalette} selectedPaletteId={selectedPalette} /> }, { name: 'Layout', component: <Settings setGridSize={setGridSize} gridSize={gridSize} setShowPixelGrid={setShowPixelGrid} showPixelGrid={showPixelGrid} showBackground={background} setShowBackground={setBackground} backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} /> }, { name: 'Save/Load', component: <SaveLoadPainting hasBackground={background} setHasBackground={setBackground} backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} setPaletteId={setSelectedPalette} setPixels={handleSetPixels} setGridSize={setGridSize} paletteId={selectedPalette} pixels={presentPixels} gridSize={gridSize} /> }, { name: "Import/Export", component: <PaintingFileHandler setPaletteId={setSelectedPalette} setPixels={handleSetPixels} setGridSize={setGridSize} setHasBackground={setBackground} setBackgroundColor={setBackgroundColor} paletteId={selectedPalette} pixels={presentPixels} gridSize={gridSize} hasBackground={background} backgroundColor={backgroundColor} /> }, { name: "Music", component: <SongSelector bubbles={bubbles} setBubbles={setBubbles} /> }]) },

  ]

  return (
    <WagmiConfig config={wagmiConf}>
      <AudioContextProvider>
        <TracingProvider>
          <div className="flex flex-col min-h-screen">
            <NavBar openModalWithTabs={openModalWithTabs} menuOptions={menuOptions} mobileMenuOptions={mobileMenuOptions} bgColor={themeColorPrimary} selectedColor={selectedColor !== null ? palettes[selectedPalette - 1].colors[selectedColor] : null} selectedTool={selectedTool} className="flex-shrink-0" />
            <div className="flex-grow bg-gray-100">
              <Visualizer>
                <Toolbar selectedTool={selectedTool} setSelectedTool={setSelectedTool} selectedColor={selectedColor} setSelectedColor={setSelectedColor} palette={palettes[selectedPalette - 1].colors} undo={undo} redo={redo} />
                <PixelGrid showBackground={background} backgroundColor={backgroundColor} selectedColor={selectedColor} palette={palettes[selectedPalette - 1].colors} pixels={presentPixels} setPixels={handleSetPixels} gridSize={gridSize} showGrid={showPixelGrid} selectedTool={selectedTool} />
                <SettingsToolbar palettes={palettes} setSelectedPaletteId={setSelectedPalette} selectedPaletteId={selectedPalette} showPixelGrid={showPixelGrid} setShowPixelGrid={setShowPixelGrid} gridSize={gridSize} setGridSize={setGridSize} showBackground={background} setShowBackground={setBackground} backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor} />
              </Visualizer>
            </div>
          </div>
          <DynamicModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} content={modalContent} />
        </TracingProvider>
      </AudioContextProvider>
    </WagmiConfig>
  );
}

export default App;
